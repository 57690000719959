import React from "react";
import checkIcon from "../../../assets/images/check-white.svg";
import restaurantPlaceholder from "../../../assets/images/book-open-02.svg";
import editIcon from "../../../assets/images/pencil-02.svg";

interface Props {
  id: number;
  name: string;
  location: string;
  distance?: number;
  setSelectedRestaurant: React.Dispatch<
    React.SetStateAction<{
      id: string | number;
      name: string;
      logo: string;
      status?: "Open" | "Close";
      address: string;
      distance?: number;
      cuisines?: string[];
      menuLink?: string;
    } | null>
  >;
  selectedRestaurantId: string | number | undefined;
  menuLink: string;
  setRestaurantEditIsOpen: React.Dispatch<
    React.SetStateAction<
      | {
          name: string;
          link: string;
          location: {
            id: number;
            name: string;
            supportText?: string | undefined;
            lng?: string;
            lat?: string;
          } | null;
          id: number;
        }
      | undefined
    >
  >;
  locationLongitude: string;
  locationLatitude: string;
  setNewRestaurantModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function UserRestaurantItem({
  id,
  name,
  location,
  selectedRestaurantId,
  setSelectedRestaurant,
  distance,
  menuLink,
  setRestaurantEditIsOpen,
  locationLongitude,
  locationLatitude,
  setNewRestaurantModalIsOpen,
}: Props) {
  return (
    <div
      className={`
        restaurantItem
        items-start
        ${selectedRestaurantId === id ? "restaurantItem-selected" : ""}
      `}
      onClick={() => {
        setSelectedRestaurant({
          id,
          name,
          status: "Open",
          logo: "",
          address: location,
          menuLink,
        });
      }}
    >
      <div className="restaurantItem-logo-custom">
        <img src={restaurantPlaceholder} alt="restaurant logo" width={40} height={40} />
      </div>
      <div className="restaurantItem-content">
        <div className="flex spaceBetween items-center gap-4 fullWidth">
          <h3 className="restaurantItem-name">{name}</h3>
          <img
            src={editIcon}
            alt=""
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setRestaurantEditIsOpen({
                name,
                link: menuLink,
                location: {
                  id: 1,
                  name: location,
                  supportText: undefined,
                  lng: locationLongitude,
                  lat: locationLatitude,
                },
                id,
              });
              setNewRestaurantModalIsOpen(true);
            }}
            style={{ zIndex: "10000" }}
          />
        </div>
        <p className="restaurantItem-info">{location}</p>
        <p className="restaurantItem-info">
          {distance ? `${distance.toFixed(2)}mi` : ""}
        </p>
      </div>
      {selectedRestaurantId === id ? (
        <img
          src={checkIcon}
          alt=""
          className="fileUploadBase-downloaded restaurantItem-selected-icon"
        />
      ) : null}
    </div>
  );
}
