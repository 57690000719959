import React from "react";
import arrowLeft from "../assets/images/arrow-narrow-left.svg";
import closeIcon from "../assets/images/x-close-black.svg";
import logoIcon from "../assets/images/logo-lg-color.svg";

export default function TermsAndConditions() {
  return (
    <div className="terms">
      <header className="terms_header">
        <div className="flex gap-12 items-center">
          <img
            className="selectRestaurant-main-info-backButton"
            src={arrowLeft}
            alt=""
            onClick={() => window.close()}
          />
          <img src={logoIcon} alt="" />
        </div>
        <img src={closeIcon} alt="" className="cursorPointer" onClick={() => window.close()} />
      </header>
      <main className="terms_main">
        <div className="terms_main-item">
          <h1 className="color-gray-900 sm font-semibold">
            Terms and Conditions
          </h1>
          <p className="color-gray-600 text-md font-regular">
            Welcome! These terms and conditions ("Terms") govern your access and
            use of the gatherwise application (the "App") and its related
            services (collectively, the "Service"). By accessing or using the
            Service, you agree to be bound by these Terms.
          </p>
        </div>
        <div className="terms_main-item">
          <p className="color-gray-600 text-md font-semibold">
            1. Beta Disclaimer
          </p>
          <p className="color-gray-600 text-md font-regular">
            The Service is currently in beta testing. This means it may contain
            bugs, errors, and other limitations. We may make changes to the
            Service at any time without notice. We encourage you to provide
            feedback to help us improve the Service.
          </p>
        </div>
        <div className="terms_main-item">
          <p className="color-gray-600 text-md font-semibold">
            2. Services Offered
          </p>
          <p className="color-gray-600 text-md font-regular">
            The Service allows meeting or event planners (the "Organizer") to
            create events and agendas, plan meals, invite attendees, manage
            RSVPs and more. The Service allows meeting or event invitees (the
            “Attendee”) to view event details, RSVP to events, select preferred
            food choices (via MealMe) and more. Note: these services are
            subjected to change with revised versions of the App. 
          </p>
        </div>
        <div className="terms_main-item">
          <p className="color-gray-600 text-md font-semibold">
            3. MealMe Integration
          </p>
          <p className="color-gray-600 text-md font-regular">
            The Service integrates with MealMe, a third-party food ordering
            platform, to allow attendees to select their food preferences. You
            agree to be bound by MealMe's terms and conditions in addition to
            these Terms. MealMe's terms can be found here:
            https://www.mealme.ai/legal.
          </p>
        </div>
        <div className="terms_main-item">
          <p className="color-gray-600 text-md font-semibold">
            4. User Accounts
          </p>
          <p className="color-gray-600 text-md font-regular">
            You may need to create an account to use the Service. You are
            responsible for maintaining the confidentiality of your account
            information and for all activities that occur under your account.
          </p>
        </div>
        <div className="terms_main-item">
          <p className="color-gray-600 text-md font-semibold">
            5. User Content
          </p>
          <p className="color-gray-600 text-md font-regular">
            You are responsible for all content you submit to the Service ("User
            Content"). You retain all ownership rights to your User Content, but
            you grant us a non-exclusive license to use, reproduce, modify,
            publish, and distribute your User Content in connection with the
            Service.
          </p>
        </div>
        <div className="terms_main-item">
          <p className="color-gray-600 text-md font-semibold">6. Disclaimers</p>
          <p className="color-gray-600 text-md font-regular">
            THE SERVICE IS PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY
            OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE SERVICE WILL BE
            UNINTERRUPTED, ERROR-FREE, OR VIRUS-FREE.
          </p>
        </div>
        <div className="terms_main-item">
          <p className="color-gray-600 text-md font-semibold">
            7. Limitation of Liability
          </p>
          <p className="color-gray-600 text-md font-regular">
            WE WILL NOT BE LIABLE FOR ANY DAMAGES ARISING OUT OF OR RELATED TO
            YOUR USE OF THE SERVICE, INCLUDING BUT NOT LIMITED TO DIRECT,
            INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES.
          </p>
        </div>
        <div className="terms_main-item">
          <p className="color-gray-600 text-md font-semibold">8. Termination</p>
          <p className="color-gray-600 text-md font-regular">
            We may terminate your access to the Service at any time, for any
            reason, with or without notice.
          </p>
        </div>
        <div className="terms_main-item">
          <p className="color-gray-600 text-md font-semibold">
            9. Governing Law
          </p>
          <p className="color-gray-600 text-md font-regular">
            These Terms shall be governed by and construed in accordance with
            the laws of the State of Maine.
          </p>
        </div>
        <div className="terms_main-item">
          <p className="color-gray-600 text-md font-semibold">
            10. Entire Agreement
          </p>
          <p className="color-gray-600 text-md font-regular">
            These Terms constitute the entire agreement between you and us
            regarding the use of the Service.
          </p>
        </div>
        <div className="terms_main-item">
          <p className="color-gray-600 text-md font-semibold">
            11. Updates to the Terms
          </p>
          <p className="color-gray-600 text-md font-regular">
            We may update these Terms at any time by posting a revised version
            on the App. You agree to be bound by the revised Terms by continuing
            to access or use the Service.
          </p>
        </div>
        <div className="terms_main-item">
          <p className="color-gray-600 text-md font-semibold">12. Contact Us</p>
          <p className="color-gray-600 text-md font-regular">
            If you have any questions about these Terms, please contact us at
            hello@gatherwise.us.
          </p>
        </div>
      </main>
      <footer className="terms_footer">© Gatherwise Inc {new Date().getFullYear()}</footer>
    </div>
  );
}
