import {
  fetchAttendeesSelections,
  fetchMeals,
  fetchRestaurantMenu,
  fetchSpecificRestaurant,
} from "../../../httpQueries/http";
import { AgendaBlockType } from "../../../types/agendaBlockType";
import { MealBlockType } from "../../../types/meals";

export function getAgendaItemOnClickProps(
  isOrganizer: boolean,
  item: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    mealBlockId?: number | undefined;
    index: number;
  },
  setSelectedRestaurant: React.Dispatch<
    React.SetStateAction<{
      id: string | number;
      name: string;
      logo: string;
      status?: "Open" | "Close" | undefined;
      address: string;
      distance?: number | undefined;
      cuisines?: string[] | undefined;
      description: string;
    } | null>
  >,
  setEventAgendaMealBlock: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        subcategory_id: string;
        menu_item_list: {
          count: number;
          name: string;
          price: number;
          qty_available: null;
          unit_size: null;
          unit_of_measurement: string;
          description: string;
          is_available: boolean;
          image: string;
          customizations: [
            {
              name: string;
              min_choice_options: number;
              max_choice_options: number;
              options: [
                {
                  name: string;
                  price: number;
                  customizations: any[];
                  min_qty: number;
                  max_qty: number;
                  conditional_price: {};
                  formatted_price: string;
                  default_qty: number;
                  option_id: string;
                }
              ];
              customization_id: string;
            }
          ];
          min_price: number;
          original_price: number;
          formatted_price: string;
          attributes: [];
          product_id: string;
          thumbnail_image: string;
          should_fetch_customizations: boolean;
          supports_image_scaling: boolean;
        }[];
      }[]
    >
  >,
  setMealBlockId: React.Dispatch<React.SetStateAction<number | undefined>>,
  setReviewUserSelectionsModalIsOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  setNotLogginedUserIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setAgendaBlockId: React.Dispatch<React.SetStateAction<number | undefined>>,
  eventId: number,
  setAttendeesSelections: React.Dispatch<
    React.SetStateAction<{
      agendaBlockName: string;
      onOrder: () => Promise<void>;
      restaurantInfo: {
        name: string;
        address: string;
        cuisines: string;
        distance: string;
      };
      ordersCount: number;
      attendeesOrder: {
        attendeeName: string;
        selections: string[];
      }[];
      total: number;
      taxRate: number;
    }>
  >,
  setReviewAttendeesSelectionsModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setOrderMealsModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setCopyAddressModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setMealBlockIdForOrder: React.Dispatch<React.SetStateAction<number>>,
  setCartId: React.Dispatch<React.SetStateAction<string | null>>,
) {
  const token = localStorage.getItem("token");

  const onClick = async () => {
    if (!token) {
      setNotLogginedUserIsOpen(true);
    }

    if (item.mealBlockId) {
      const mealBlock = await fetchMeals({
        id: item.mealBlockId!,
        token,
      });

      if (
        mealBlock?.mealBlock?.restaurantId
      ) {
        fetchRestaurantMenu({
          id: mealBlock.mealBlock.restaurantId,
          token,
          isPickup: mealBlock.mealBlock.type === MealBlockType.Pickup,
        }).then((menu) => {
          if (menu) {
            const preSelectedMeals: {
              name: string;
              subcategory_id: string;
              menu_item_list: {
                count: number;
                name: string;
                price: number;
                qty_available: null;
                unit_size: null;
                unit_of_measurement: string;
                description: string;
                is_available: boolean;
                image: string;
                customizations: [
                  {
                    name: string;
                    min_choice_options: number;
                    max_choice_options: number;
                    options: [
                      {
                        name: string;
                        price: number;
                        customizations: any[];
                        min_qty: number;
                        max_qty: number;
                        conditional_price: {};
                        formatted_price: string;
                        default_qty: number;
                        option_id: string;
                      }
                    ];
                    customization_id: string;
                  }
                ];
                min_price: number;
                original_price: number;
                formatted_price: string;
                attributes: [];
                product_id: string;
                thumbnail_image: string;
                should_fetch_customizations: boolean;
                supports_image_scaling: boolean;
              }[];
            }[] = [];

            const mealsIds = mealBlock.mealBlock.preSelectedMealsIds;

            if (mealsIds?.length) {
              menu.map((category) =>
                category.menu_item_list.map((product) => {
                  if (mealsIds.includes(product.product_id)) {
                    const preSelectedCategories = preSelectedMeals.map(
                      (item) => item.subcategory_id
                    );
                    if (
                      preSelectedCategories.includes(category.subcategory_id)
                    ) {
                      const currentCategory = preSelectedMeals.find(
                        (item) =>
                          item.subcategory_id === category.subcategory_id
                      );

                      if (currentCategory) {
                        preSelectedMeals[
                          preSelectedMeals.findIndex(
                            (category) =>
                              category.subcategory_id ===
                              currentCategory.subcategory_id
                          )
                        ] = {
                          ...currentCategory,
                          menu_item_list: [
                            ...currentCategory.menu_item_list,
                            {
                              count: 0,
                              ...product,
                            },
                          ],
                        };
                      }
                    } else {
                      preSelectedMeals.push({
                        ...category,
                        menu_item_list: [
                          {
                            count: 0,
                            ...product,
                          },
                        ],
                      });
                    }
                  }
                })
              );

              setEventAgendaMealBlock(preSelectedMeals);
            }
          }
        });

        const restaurant = await fetchSpecificRestaurant({
          id: mealBlock.mealBlock.restaurantId,
          token,
          eventId: eventId
        });

        setSelectedRestaurant(
          restaurant
            ? {
                id: restaurant._id,
                name: restaurant.name,
                logo: restaurant.logo_photos[0],
                status: restaurant.is_open ? "Open" : "Close",
                address: restaurant.address.street_addr,
                distance: restaurant.miles,
                cuisines: restaurant.cuisines,
                description: restaurant.description,
              }
            : null
        );

        if (isOrganizer) {
          const selections = await fetchAttendeesSelections({
            token,
            eventId,
            mealBlockId: item.mealBlockId,
          });

          if (selections) {
            setAttendeesSelections({
              agendaBlockName: item.name,
              onOrder: async () => {
                setMealBlockIdForOrder(mealBlock.mealBlock.id);
                setCartId(
                  mealBlock.mealBlock.cartId
                );
                setCopyAddressModalIsOpen(true);
                setOrderMealsModalIsOpen(true);
              },
              restaurantInfo: {
                name: selections?.restaurantName,
                address: selections?.restaurantAddress.street_addr,
                cuisines: selections?.cuisines.join(', '),
                distance: `${selections?.distance.toFixed(2)}mi`,
              },
              ordersCount: selections?.userSelectionsTextData.length,
              attendeesOrder: selections?.userSelectionsTextData.map(
                (selection) => ({
                  attendeeName: selection.userName,
                  selections: selection.mealNames,
                })
              ),
              total: selections?.totalPrice / 100,
              taxRate: 5,
            });
          }
        }

        setMealBlockId(item.mealBlockId);
        setAgendaBlockId(+item.id);

        if (isOrganizer) {
          setReviewAttendeesSelectionsModalIsOpen(true);
        } else {
          setReviewUserSelectionsModalIsOpen(true);
        }
      }
    }
  };

  return onClick;
}
