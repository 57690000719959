import { DropResult } from "react-beautiful-dnd";
import { AgendaBlockType } from "../types/agendaBlockType";

export const onDragEnd = (
  result: DropResult,
  setIsDrag: React.Dispatch<React.SetStateAction<boolean>>,
  agenda: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number;
    isEditable: boolean;
  }[],
  setAgenda: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        icon: string;
        name: string;
        description: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        };
        startTime: string;
        endTime: string;
        type: AgendaBlockType;
        index: number;
        isEditable: boolean;
      }[]
    >
  >
) => {
  const { destination, source } = result;

  if (!destination || !source) {
    return;
  }

  const agendaWithoutDraggableItem = [
    ...agenda.slice(0, source.index),
    ...agenda.slice(source.index + 1),
  ];

  const updatedAgendaArray: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number;
    isEditable: boolean;
  }[] = [
    ...agendaWithoutDraggableItem.slice(0, destination?.index),
    agenda[source.index],
    ...agendaWithoutDraggableItem.slice(destination?.index),
  ].map((item, index) => ({ ...item, index }));

  setAgenda(updatedAgendaArray);

  setIsDrag(true);
};
