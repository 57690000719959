import React from "react";
import bellIcon from "../../assets/images/bell-sm.svg";
import arrowIcon from "../../assets/images/chevron-right-black.svg";

interface Props {
  ordered: number;
  invited: number;
  onArrowClick: () => Promise<void>;
  agendaBlockName: string;
}

export default function UsersThatMadeOrderCountWidget({
  ordered,
  invited,
  onArrowClick,
  agendaBlockName,
}: Props) {
  return (
    <div className="flex flex-column gap-4 fullWidth">
      <div className="flex items-center spaceBetween gap-4">
        <div className="flex items-center gap-4">
          <img src={bellIcon} alt="bell" width={16} height={16} />
          <p className="text-xs font-regular color-black">
            {agendaBlockName} meal
          </p>
        </div>
        <img
          src={arrowIcon}
          alt="arrow right"
          className="cursor-pointer"
          onClick={onArrowClick}
          width={16}
          height={16}
        />
      </div>
      <div className="flex items-end gap-8">
        <h3 className="text-xl font-bold color-black">
          {ordered} of {invited}
        </h3>
        <p
          className="text-xs font-regular color-black"
          style={{ lineHeight: "24px" }}
        >
          have ordered food
        </p>
      </div>
    </div>
  );
}
