import React from "react";
import AgendaItem from "../Agenda/AgendaItem";
import { AgendaBlockType } from "../../types/agendaBlockType";

interface Props {
  agenda: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number;
    mealBlockId?: number;
    onClick?: () => Promise<void>;
    isEditable: boolean;
    finalizedUsersId?: number;
  }[];
  withoutLabel?: boolean;
  setShowAgendaModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentItem?: React.Dispatch<
    React.SetStateAction<
      | {
          id: string;
          icon: string;
          name: string;
          description: string;
          duration: {
            id: number;
            name: string;
            value?: number | undefined;
          };
          startTime: string;
          endTime: string;
          type: AgendaBlockType;
          index: number;
          isEditable: boolean;
        }
      | undefined
    >
  >;
  setAgenda?: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        icon: string;
        name: string;
        description: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        };
        startTime: string;
        endTime: string;
        type: AgendaBlockType;
        index: number;
        isEditable: boolean;
        finalizedUsersId?: number;
      }[]
    >
  >;
  withActions?: boolean;
  onDelete?: (agendaName: string, agendaId: string) => void;
  setSubBlocks?: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        name: string;
        startTime: string;
        endTime: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        } | null;
        index: number;
        docsId: number[];
        agendaItemId: string;
      }[]
    >
  >;
  subBlocks?: {
    id: string;
    name: string;
    startTime: string;
    endTime: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    } | null;
    index: number;
    docsId: number[];
    agendaItemId: string;
  }[];
  setCurrentMealBlockId?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  invitedUsers?: number;
}

export default function ReviewAgendaSection({
  agenda,
  withoutLabel,
  setShowAgendaModal,
  setAgenda,
  setCurrentItem,
  withActions,
  onDelete,
  setSubBlocks,
  subBlocks,
  setCurrentMealBlockId,
  invitedUsers,
}: Props) {
  return (
    <div
      className={`${
        withoutLabel ? "reviewAgenda" : "flex flex-column gap-20 fullWidth"
      }`}
    >
      {withoutLabel ? <h3 className="reviewAgenda-title">Agenda</h3> : null}
      {agenda
        .sort((it1, it2) => it1.index - it2.index)
        .map((item) => (
          <AgendaItem
            {...item}
            height="max"
            key={item.id}
            setShowAgendaModal={setShowAgendaModal}
            setAgenda={setAgenda}
            setCurrentItem={setCurrentItem}
            isReviewPageWithActions={
              withActions && item.type !== AgendaBlockType.Meal
            }
            onDelete={onDelete}
            agenda={agenda}
            subBlocks={subBlocks}
            setSubBlocks={setSubBlocks}
            setCurrentMealBlockId={setCurrentMealBlockId}
            orderedUsers={item.finalizedUsersId}
            invitedUsers={invitedUsers}
          />
        ))}
    </div>
  );
}
