import React, { useState } from "react";
import { Button } from "../Buttons/Button";
import HorizontalTabs from "../Tabs/HorizontalTabs";
import dotsIcon from "../../assets/images/dots-vertical.svg";
import closeIcon from "../../assets/images/x-close.svg";
import { logoColor } from "../../types/logoColor";
import { logoImage } from "../../types/logoImage";
import { AttendeeRSVP } from "../../types/attendeeRSVP";
import { getUTCTimeFromMinutes } from "../../utilities/getUTCTimeFromMinutes";
import moment from "moment";
import { getEventLogo } from "../../utilities/getEventLogoImage";
import DotsModal from "../Modal/DotsModal";
import editDotsIcon from "../../assets/images/edit-01.svg";
import deleteIcon from "../../assets/images/trash-01-black.svg";
import inviteIcon from "../../assets/images/calendar-plus-02.svg";
import {
  deleteAttendeeFromContacts,
  fetchEvent,
  fetchEvents,
  postAttendee,
} from "../../httpQueries/http";
import { useQuery } from "@tanstack/react-query";
import { EventsFilter } from "../../types/eventsFilter";
import checkIcon from "../../assets/images/check.svg";

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showUserInfo: {
    id: number;
    fullName: string;
    email: string;
    phone?: string;
    businessName: string;
    address: string;
    title: string;
    updatedAt: string;
    user: {
      id: number;
      email: string;
      fullName: string;
      phone?: null | string;
    };
    events: [
      {
        id: number;
        name: string;
        slug: string;
        description: string;
        timezone: string;
        color: null | logoColor;
        pattern: null | logoImage;
        startTime: string;
        endTime: string;
        attendee: {
          email: string;
          userId: number;
          status: "added";
          rsvp: null | AttendeeRSVP;
        };
      }
    ];
  } | null;
  setUserUpdated: React.Dispatch<React.SetStateAction<number | null>>;
  setUserDeleted: React.Dispatch<React.SetStateAction<number | null>>;
  setUserFullName: React.Dispatch<React.SetStateAction<string>>;
  setUserEmail: React.Dispatch<React.SetStateAction<string>>;
  setUserPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  setUserBusinessName: React.Dispatch<React.SetStateAction<string>>;
  setUserTitle: React.Dispatch<React.SetStateAction<string>>;
  setIsNewUserModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setShowUserInfo: React.Dispatch<
    React.SetStateAction<{
      id: number;
      fullName: string;
      email: string;
      phone?: string;
      businessName: string;
      address: string;
      title: string;
      updatedAt: string;
      user: {
        id: number;
        email: string;
        fullName: string;
        phone?: null | string;
      };
      events: [
        {
          id: number;
          name: string;
          slug: string;
          description: string;
          timezone: string;
          color: null | logoColor;
          pattern: null | logoImage;
          startTime: string;
          endTime: string;
          attendee: {
            email: string;
            userId: number;
            status: "added";
            rsvp: null | AttendeeRSVP;
          };
        }
      ];
    } | null>
  >;
}

export default function ReviewContactDetails({
  setIsOpen,
  showUserInfo,
  setUserUpdated,
  setUserBusinessName,
  setUserEmail,
  setUserFullName,
  setUserPhoneNumber,
  setUserTitle,
  setUserDeleted,
  setIsNewUserModalOpen,
  setShowUserInfo,
}: Props) {
  const token = localStorage.getItem("token");
  const [currentTab, setCurrentTab] = useState<{
    id: number;
    name: string;
  } | null>({ id: 1, name: "Events" });
  const { data: events } = useQuery({
    queryKey: ["events", token, showUserInfo?.id],
    queryFn: () =>
      fetchEvents({
        start: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getMonth(),
          0,
          0,
          0,
          0
        ).valueOf(),
        end: new Date(
          new Date().getFullYear() + 10,
          new Date().getMonth(),
          new Date().getMonth(),
          23,
          59,
          59,
          999
        ).valueOf(),
        token,
        eventFilter: EventsFilter.Organizer,
        isPublished: true,
      }),
  });
  const [isEventDropdownOpen, setIsEventDropdownOpen] = useState(false);
  const [isDotsModalOpen, setIsDotsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);

  if (!showUserInfo) {
    setIsOpen(false);
    return null;
  }

  return (
    <div className="reviewContactDetails">
      {isEventDropdownOpen ? (
        <div className="reviewContactDetails-eventsDropdown">
          {events?.events.map((event) => (
            <div
              className="reviewContactDetails-eventsDropdown-item"
              onClick={() => setSelectedEvent(event.slug)}
              key={event.slug}
            >
              <div className="reviewContactDetails-eventsDropdown-item-info">
                <div
                  className={`
                        reviewEventDetailsPage-header-mainImage
                        reviewEventDetailsPage-header-mainImage-sm
                        ${
                          event.color === logoColor.YELLOW
                            ? "reviewEventDetailsPage-header-mainImage-yellow"
                            : ""
                        }
                        ${
                          event.color === logoColor.BLUELIGHT
                            ? "reviewEventDetailsPage-header-mainImage-blueLight"
                            : ""
                        }
                        ${
                          event.color === logoColor.RED
                            ? "reviewEventDetailsPage-header-mainImage-error"
                            : ""
                        }
                        ${
                          event.color === logoColor.PURPLE
                            ? "reviewEventDetailsPage-header-mainImage-purple"
                            : ""
                        }
                        ${
                          event.color === logoColor.GREEN
                            ? "reviewEventDetailsPage-header-mainImage-success"
                            : ""
                        }
                        ${
                          event.color === logoColor.ORANGE
                            ? "reviewEventDetailsPage-header-mainImage-warning"
                            : ""
                        }
                      `}
                >
                  <img src={getEventLogo(event.pattern)} alt="" />
                </div>
                <div className="flex flex-column">
                  <h3 className="eventReviewBlockV2-content-header-title">
                    {event.name}
                  </h3>
                  <p className="eventReviewBlockV2-content-header-description-lite">
                    {event.description}
                  </p>
                </div>
              </div>
              {selectedEvent === event.slug ? (
                <img src={checkIcon} alt="" />
              ) : null}
            </div>
          ))}
          <div className="reviewContactDetails-eventsDropdown-actions">
            <Button
              size={"md"}
              hierarchy={"errorSecondaryGray"}
              buttonType={"regular"}
              fullWidth
              contentCenter
              onClick={() => {
                setIsEventDropdownOpen(false);
                setSelectedEvent(null);
              }}
            >
              Cancel
            </Button>
            <Button
              size={"md"}
              hierarchy={"primary"}
              buttonType={"regular"}
              fullWidth
              contentCenter
              disabled={!selectedEvent}
              asyncOnClick={async () => {
                if (selectedEvent) {
                  const eventId = await fetchEvent({
                    slug: selectedEvent,
                    token,
                  });

                  if (eventId) {
                    await postAttendee({
                      body: {
                        email: showUserInfo.email,
                        businessName: showUserInfo.businessName,
                        address: showUserInfo.address,
                        title: showUserInfo.title,
                        eventId: eventId.id,
                        fullName: showUserInfo.fullName,
                        phone: showUserInfo.phone,
                      },
                      token,
                    });

                    setIsEventDropdownOpen(false);
                    setUserUpdated(eventId.id);
                  }
                }
              }}
            >
              Invite
            </Button>
          </div>
        </div>
      ) : null}
      <div className="reviewContactDetails-header">
        <h3 className="reviewContactDetails-header-title">Contact details</h3>
        <div className="reviewContactDetails-header-actions positionRelative">
          <Button
            size="md"
            hierarchy="closeGray"
            buttonType="small"
            buttonImage={dotsIcon}
            onClick={() => setIsDotsModalOpen((prev) => !prev)}
          />
          <>
            {isDotsModalOpen ? (
              <DotsModal
                items={[
                  {
                    id: 1,
                    img: editDotsIcon,
                    text: "Edit",
                    onClick: (e) => {
                      e.stopPropagation();
                      setUserUpdated(showUserInfo.id);
                      setUserBusinessName(showUserInfo.businessName);
                      setUserEmail(showUserInfo.email);
                      setUserFullName(showUserInfo.fullName);
                      setUserPhoneNumber(showUserInfo.phone || "");
                      setUserTitle(showUserInfo.title);
                      setIsNewUserModalOpen(true);
                    },
                  },
                  {
                    id: 3,
                    img: inviteIcon,
                    text: "Invite to event",
                    onClick: (e) => {
                      e.stopPropagation();
                      setIsEventDropdownOpen(true);
                    },
                  },
                  {
                    id: 2,
                    img: deleteIcon,
                    text: "Delete",
                    onClick: (e) => {
                      e.stopPropagation();
                      deleteAttendeeFromContacts({
                        token,
                        attendeeId: showUserInfo.id,
                      });
                      setUserDeleted(showUserInfo.id);
                    },
                  },
                ]}
                setIsOpen={() => {
                  setIsDotsModalOpen(false);
                }}
                top="60px"
                right="0"
              />
            ) : null}
          </>
          <Button
            size="md"
            hierarchy="closeGray"
            buttonType="close"
            buttonImage={closeIcon}
            onClick={() => {
              setIsOpen(false);
              setShowUserInfo(null);
            }}
          />
        </div>
      </div>
      <div className="reviewContactDetails-userInfo">
        <h1 className="reviewContactDetails-userInfo-name">
          {showUserInfo.user
            ? showUserInfo.user.fullName
            : showUserInfo.fullName}
        </h1>
        <p className="reviewContactDetails-userInfo-email">
          {showUserInfo.email}
        </p>
      </div>
      <HorizontalTabs
        tabs={[
          { id: 1, name: "Events" },
          { id: 2, name: "Personal info" },
        ]}
        current={currentTab}
        setCurrent={setCurrentTab}
        type="Underline"
      />
      {currentTab?.name === "Events" ? (
        <div className="reviewContactDetails-userEvents overflowYScroll">
          <p className="reviewContactDetails-userEvents-title">Events</p>
          {showUserInfo.events
            ? showUserInfo.events.map((event) => (
                <div
                  className="reviewContactDetails-userEvents-event"
                  key={event.id}
                >
                  <div
                    className={`
                        reviewEventDetailsPage-header-mainImage
                        reviewEventDetailsPage-header-mainImage-md
                        ${
                          event.color === logoColor.YELLOW
                            ? "reviewEventDetailsPage-header-mainImage-yellow"
                            : ""
                        }
                        ${
                          event.color === logoColor.BLUELIGHT
                            ? "reviewEventDetailsPage-header-mainImage-blueLight"
                            : ""
                        }
                        ${
                          event.color === logoColor.RED
                            ? "reviewEventDetailsPage-header-mainImage-error"
                            : ""
                        }
                        ${
                          event.color === logoColor.PURPLE
                            ? "reviewEventDetailsPage-header-mainImage-purple"
                            : ""
                        }
                        ${
                          event.color === logoColor.GREEN
                            ? "reviewEventDetailsPage-header-mainImage-success"
                            : ""
                        }
                        ${
                          event.color === logoColor.ORANGE
                            ? "reviewEventDetailsPage-header-mainImage-warning"
                            : ""
                        }
                      `}
                  >
                    <img src={getEventLogo(event.pattern)} alt="" />
                  </div>
                  <div className="reviewContactDetails-userEvents-event-info">
                    <div className="reviewContactDetails-userEvents-event-header">
                      <p className="reviewContactDetails-userEvents-event-header-name">
                        {event.name}
                      </p>
                      <p
                        className={`
                              reviewContactDetails-userEvents-event-header-rsvp
                              ${
                                event.attendee.rsvp === AttendeeRSVP.Yes
                                  ? "reviewContactDetails-userEvents-event-header-rsvp-yes"
                                  : ""
                              }

                              ${
                                event.attendee.rsvp === AttendeeRSVP.No
                                  ? "reviewContactDetails-userEvents-event-header-rsvp-no"
                                  : ""
                              }

                              ${
                                event.attendee.rsvp === AttendeeRSVP.Maybe
                                  ? "reviewContactDetails-userEvents-event-header-rsvp-maybe"
                                  : ""
                              }

                              ${
                                !event.attendee.rsvp
                                  ? "reviewContactDetails-userEvents-event-header-rsvp-pending"
                                  : ""
                              }
                            `}
                      >
                        {!event.attendee.rsvp
                          ? "Pending"
                          : event.attendee.rsvp === AttendeeRSVP.Yes
                          ? "Yes"
                          : event.attendee.rsvp === AttendeeRSVP.No
                          ? "No"
                          : "Maybe"}
                      </p>
                    </div>
                    <p className="reviewContactDetails-userEvents-event-description">
                      {event.description}
                    </p>
                    <p className="reviewContactDetails-userEvents-event-time">
                      {moment(event.startTime).format("ll")} •{" "}
                      {getUTCTimeFromMinutes(event.startTime)}
                    </p>
                  </div>
                </div>
              ))
            : null}
        </div>
      ) : (
        <div className="reviewContactDetails-userDetails">
          <div className="reviewContactDetails-userDetails-item">
            <p className="reviewContactDetails-userDetails-item-description">
              Full name
            </p>
            <p className="reviewContactDetails-userDetails-item-title">
              {showUserInfo.user
                ? showUserInfo.user.fullName
                : showUserInfo.fullName}
            </p>
          </div>
          <div className="reviewContactDetails-userDetails-item">
            <p className="reviewContactDetails-userDetails-item-description">
              Email address
            </p>
            <p className="reviewContactDetails-userDetails-item-title">
              {showUserInfo.email}
            </p>
          </div>
          <div className="reviewContactDetails-userDetails-item">
            <p className="reviewContactDetails-userDetails-item-description">
              Phone number
            </p>
            <p className="reviewContactDetails-userDetails-item-title">{`${
              showUserInfo.phone?.length ? showUserInfo.phone : "-"
            }`}</p>
          </div>
          <div className="reviewContactDetails-userDetails-item">
            <p className="reviewContactDetails-userDetails-item-description">
              Location
            </p>
            <div className="flex flex-column items-end">
              <p className="reviewContactDetails-userDetails-item-title">{`${
                showUserInfo.address ? showUserInfo.address.split(",")[0] : "-"
              }`}</p>
              {showUserInfo.address ? (
                <p className="reviewContactDetails-userDetails-item-description">{`${
                  showUserInfo.address
                    ? showUserInfo.address.split(",")[1]
                    : "-"
                }`}</p>
              ) : null}
            </div>
          </div>
          <div className="reviewContactDetails-userDetails-item">
            <p className="reviewContactDetails-userDetails-item-description">
              Business name
            </p>
            <p className="reviewContactDetails-userDetails-item-title">{`${
              showUserInfo.businessName ? showUserInfo.businessName : "-"
            }`}</p>
          </div>
          <div className="reviewContactDetails-userDetails-item">
            <p className="reviewContactDetails-userDetails-item-description">
              Title
            </p>
            <p className="reviewContactDetails-userDetails-item-title">{`${
              showUserInfo.title ? showUserInfo.title : "-"
            }`}</p>
          </div>
        </div>
      )}
    </div>
  );
}
