import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/images/x-close-gray-lg.svg";
import { Button } from "../Buttons/Button";
import { FormInput } from "../Inputs/FormInput";
import Counter from "../Counter/Counter";
import RadioButtons from "../Selectors/RadioButtons";
import SelectOptions from "../Selectors/SelectOptions";

interface Props {
  setShow: () => void;
  setTotalSum: React.Dispatch<React.SetStateAction<number>>;
  customizations: {
    name: string;
    min_choice_options: number;
    max_choice_options: number;
    options: [
      {
        name: string;
        price: number;
        customizations: any[];
        min_qty: number;
        max_qty: number;
        conditional_price: {};
        formatted_price: string;
        default_qty: number;
        option_id: string;
      }
    ];
    customization_id: string;
  }[];
  agendaBlockId: number;
  name: string;
  description: string;
  productId: string;
  markedPrice: number;
  onDescrease: () => void;
  onIncrease: () => void;
  count: number;
  setOrders: React.Dispatch<
    React.SetStateAction<
      | {
          agendaBlockId: number;
          customizations: {
            customizationId: string;
            optionId: string[];
            markedPrice: number;
          }[];
          productId: string;
          markedPrice: number;
          notes: string;
          count: number;
        }
      | undefined
    >
  >;
  onAddMeal: () => void;
  preOrder:
    | {
        agendaBlockId: number;
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number;
      }
    | undefined;
  onInputChange: (count: number) => void;
  onTextFieldChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  textFieldValue: string;
  orders: {
    agendaBlockId: number;
    customizations: {
      customizationId: string;
      optionId: string[];
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  }[];
}

export default function SelectMealCustomizationModal({
  setShow,
  customizations,
  name,
  description,
  productId,
  markedPrice,
  onDescrease,
  onIncrease,
  count,
  setOrders,
  onAddMeal,
  preOrder,
  setTotalSum,
  onInputChange,
  onTextFieldChange,
  textFieldValue,
  agendaBlockId,
  orders
}: Props) {
  const productWithCustomizationsPrice = (
    count * (markedPrice / 100) +
    count *
      (preOrder
        ? preOrder.customizations.reduce(
            (currentValue, option) => currentValue + option.markedPrice,
            0
          ) / 100
        : 0)
  ).toFixed(2);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);

  useEffect(() => {
    setButtonIsDisabled(!checkIsAllRequiredOptionsAreSelected());
  }, [preOrder]);

  useEffect(() => {
    window.scrollTo(0, 0);

    document.getElementById("modal")?.classList.add("modal-main-container");
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
      document
        .getElementById("modal")
        ?.classList.remove("modal-main-container");
    };
  }, []);

  function checkIsAllRequiredOptionsAreSelected() {
    let countOfRequiredOptions = 0;
    let selectedRequiredOptionsCount = 0;

    customizations?.map((customization) => {
      if (customization.min_choice_options === 1) {
        countOfRequiredOptions += 1;

        if (
          preOrder?.customizations
            .map(
              (custom) =>
                custom.customizationId === customization.customization_id
            )
            .some((it) => it === true)
        ) {
          selectedRequiredOptionsCount += 1;
        }
      }
    });

    return countOfRequiredOptions === selectedRequiredOptionsCount;
  }

  if (true) {
    document.getElementById("notes")?.focus();
  }

  return (
    <div className="modalNotification" style={{ zIndex: "10002" }}>
      <div className="modalNotification-container modalNotification-container-selectMealsCustomization">
        <div className="modalNotification-header gap-8">
          <div className="modalNotification-header-actions">
            <h3>{name}</h3>
            <img
              src={closeIcon}
              alt="close icon"
              className="modalNotification-header-actions-close"
              onClick={setShow}
            />
          </div>
          <div className="modalNotification-header-info">
            <p>{description}</p>
          </div>
        </div>
        <div className="modalNotification-main">
          {customizations?.map((customization) => {
            if (customization.max_choice_options <= 1) {
              return (
                <RadioButtons
                  options={customization.options.map((item) => {
                    let selectedCustomizationOptionIds: string[] = [];
                    preOrder?.customizations.map((optionCustomization) => {
                      selectedCustomizationOptionIds = [
                        ...selectedCustomizationOptionIds,
                        ...optionCustomization.optionId,
                      ];
                    });

                    return {
                      ...item,
                      onClick: () => {
                        setOrders((prevState) => {
                          if (
                            selectedCustomizationOptionIds.includes(
                              item.option_id
                            ) &&
                            prevState
                          ) {
                            return {
                              ...prevState,
                              notes: textFieldValue,
                              customizations: prevState.customizations.filter(
                                (prevCustomization) =>
                                  prevCustomization.customizationId !==
                                  customization.customization_id
                              ),
                            };
                          } else {
                            if (prevState) {
                              if (
                                prevState.customizations
                                  .map((option) => option.customizationId)
                                  .includes(customization.customization_id)
                              ) {
                                return {
                                  ...prevState!,
                                  notes: textFieldValue,
                                  customizations: [
                                    ...prevState.customizations.filter(
                                      (custom) =>
                                        custom.customizationId !==
                                        customization.customization_id
                                    ),
                                    {
                                      customizationId:
                                        customization.customization_id,
                                      optionId: [item.option_id],
                                      markedPrice: item.price,
                                    },
                                  ],
                                };
                              }

                              return {
                                ...prevState,
                                notes: textFieldValue,
                                customizations: [
                                  ...prevState!.customizations,
                                  {
                                    customizationId:
                                      customization.customization_id,
                                    optionId: [item.option_id],
                                    markedPrice: item.price,
                                  },
                                ],
                              };
                            } else {
                              return {
                                customizations: [
                                  {
                                    customizationId:
                                      customization.customization_id,
                                    optionId: [item.option_id],
                                    markedPrice: item.price,
                                  },
                                ],
                                productId: productId,
                                markedPrice: markedPrice,
                                notes: textFieldValue,
                                count: 1,
                                agendaBlockId,
                              };
                            }
                          }
                        });
                      },
                      isSelected: selectedCustomizationOptionIds.includes(
                        item.option_id
                      ),
                    };
                  })}
                  label={customization.name}
                  isRequired={customization.min_choice_options >= 1}
                  key={customization.customization_id}
                />
              );
            }

            if (customization.max_choice_options > 1) {
              return (
                <SelectOptions
                  options={customization.options.map((item) => {
                    let selectedCustomizationOptionIds: string[] = [];
                    preOrder?.customizations.map((optionCustomization) => {
                      selectedCustomizationOptionIds = [
                        ...selectedCustomizationOptionIds,
                        ...optionCustomization.optionId,
                      ];
                    });

                    return {
                      ...item,
                      max_qty: customization.max_choice_options,
                      onClick: () => {
                        setOrders((prevState) => {
                          if (!prevState) {
                            return {
                              customizations: [
                                {
                                  customizationId:
                                    customization.customization_id,
                                  optionId: [item.option_id],
                                  markedPrice: item.price,
                                },
                              ],
                              productId: productId,
                              markedPrice: markedPrice,
                              notes: textFieldValue,
                              count: 1,
                              agendaBlockId,
                            };
                          }
                          // Clone existing state
                          let updatedCustomizations = [
                            ...prevState.customizations,
                          ];
                          // Find the current customization group
                          const existingCustomizationIndex =
                            updatedCustomizations.findIndex(
                              (custom) =>
                                custom.customizationId ===
                                customization.customization_id
                            );
                          if (existingCustomizationIndex !== -1) {
                            const existingCustomization =
                              updatedCustomizations[existingCustomizationIndex];
                            if (
                              existingCustomization.optionId.includes(
                                item.option_id
                              )
                            ) {
                              // **Deselecting the option**
                              const updatedOptionIds =
                                existingCustomization.optionId.filter(
                                  (id) => id !== item.option_id
                                );
                              if (updatedOptionIds.length === 0) {
                                // Remove entire customization if no options remain
                                updatedCustomizations.splice(
                                  existingCustomizationIndex,
                                  1
                                );
                              } else {
                                // Update the customization with the remaining selected options
                                updatedCustomizations[
                                  existingCustomizationIndex
                                ] = {
                                  ...existingCustomization,
                                  optionId: updatedOptionIds,
                                  markedPrice: updatedOptionIds.reduce(
                                    (sum, id) => {
                                      const option = customization.options.find(
                                        (opt) => opt.option_id === id
                                      );
                                      return sum + (option ? option.price : 0);
                                    },
                                    0
                                  ),
                                };
                              }
                            } else {
                              // **Selecting a new option**
                              updatedCustomizations[
                                existingCustomizationIndex
                              ] = {
                                ...existingCustomization,
                                optionId: [
                                  ...existingCustomization.optionId,
                                  item.option_id,
                                ],
                                markedPrice:
                                  existingCustomization.markedPrice +
                                  item.price,
                              };
                            }
                          } else {
                            // **Adding a new group**
                            updatedCustomizations.push({
                              customizationId: customization.customization_id,
                              optionId: [item.option_id],
                              markedPrice: item.price,
                            });
                          }
                          return {
                            ...prevState,
                            notes: textFieldValue,
                            customizations: updatedCustomizations,
                          };
                        });
                      },
                      isSelected: (() => {
                        let isSelected = false;
                        preOrder?.customizations.map((optionCustomization) =>
                          optionCustomization.optionId.includes(item.option_id)
                            ? (isSelected = true)
                            : false
                        );
                        return isSelected;
                      })(),
                    };
                  })}
                  isRequired={customization.min_choice_options >= 1}
                  label={customization.name}
                  selectedAmount={
                    preOrder?.customizations.find(
                      (optionCustomization) =>
                        optionCustomization.customizationId ===
                        customization.customization_id
                    )?.optionId.length || 0
                  }
                />
              );
            }
          })}
          <FormInput
            type={"TextArea"}
            label={textFieldValue.length ? "Special instructions" : ""}
            placeholder="Enter any special instructions"
            fullWidth
            value={textFieldValue}
            onChange={onTextFieldChange}
            id="notes"
          />
        </div>
        <div
          className="modalNotification-actions modalNotification-actions-flex-end"
          style={{ marginLeft: "auto" }}
        >
          <Counter
            onDescrease={onDescrease}
            onIncrease={onIncrease}
            count={count}
            onInputChange={onInputChange}
          />
          <Button
            buttonType="regular"
            hierarchy="newDesign-primary"
            size="lg"
            contentCenter
            newDesignPaddingNone
            // fullWidth={!isDesktopSize}
            onClick={() => {
              if (!!preOrder?.count) {
                onAddMeal();
              } else {
                setOrders({
                  agendaBlockId,
                  customizations: [],
                  productId: productId,
                  markedPrice: markedPrice,
                  notes: textFieldValue,
                  count: 1,
                });
              }
              setTotalSum(
                (prevState) => {
                  const currentOrder = orders.find(item => item.agendaBlockId === agendaBlockId);
                  if (currentOrder) {
                    const currentOrderSum = (currentOrder.customizations.map(customization => customization.markedPrice).reduce((previousValue, current) => previousValue + current, 0) + currentOrder.markedPrice) * currentOrder.count;

                    return (prevState - (currentOrderSum / 100)) + +productWithCustomizationsPrice;
                  }

                  return prevState + +productWithCustomizationsPrice;
                }
              );
            }}
            disabled={buttonIsDisabled}
          >
            Add {name.toLocaleLowerCase()} (${productWithCustomizationsPrice})
          </Button>
        </div>
      </div>
    </div>
  );
}
