import React from "react";
import closeIcon from "../../assets/images/x-close-white-lg.svg";
import { dismissAlphaReleaseBanner } from "../../httpQueries/http";
import { useDispatch, useSelector } from "../../store/hooks";
import { currentUserActions } from "../../store/currentUser";
import { getCurrentUser } from "../../store/currentUser/selectors";

export default function AlphaReleaseBanner() {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);

  return (
    <div className="alphaReleaseBanner">
      <div className="flex flex-column gap-4">
        <h2 className="alphaReleaseBanner-title">
          Welcome to our Alpha Release!
        </h2>
        <p className="alphaReleaseBanner-description">
          Thanks for being an early adopter! This is an early version and we
          have lots of additional features coming soon. Your feedback is so
          important to us - let us know what you think!
        </p>
      </div>
      <img
        src={closeIcon}
        alt=""
        className="cursor-pointer"
        onClick={async () => {
          await dismissAlphaReleaseBanner({ token });
          dispatch(
            currentUserActions.setCurrentUser({
              fullName: currentUser.fullName,
              email: currentUser.email,
              profileImage: currentUser.profileImage,
              completeTutorial: currentUser.completeTutorial,
              isDemoNotificationDismissed: true,
            })
          );
        }}
      />
    </div>
  );
}
