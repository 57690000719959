import React, { useState } from "react";
import CustomScheduleDay from "./CustomScheduleDay";
import startOfWeek from "date-fns/startOfWeek";
import format from "date-fns/format";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import endOfWeek from "date-fns/endOfWeek";
import isSameMonth from "date-fns/isSameMonth";
import addDays from "date-fns/addDays";
import { fetchEvents } from "../../httpQueries/http";
import { getTimeFromMinutes } from "../../utilities/getTimeFromMinutes";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { EventsFilter } from "../../types/eventsFilter";
import { isToday } from "date-fns";
import DaySchedule from "./DaySchedule";
import ReviewEventDetailsModal from "../Modal/ReviewEventDetailsModal";

function RenderCells(
  currentMonth: Date,
  filter: EventsFilter,
  currentTab: "month" | "week" | "day",
  searchValue: string,
  setIsReviewEventModalOpen: React.Dispatch<React.SetStateAction<string>>
) {
  const token = localStorage.getItem("token");
  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(monthStart);
  const startDate =
    currentTab === "day"
      ? currentMonth
      : startOfWeek(currentTab === "month" ? monthStart : currentMonth, {
          weekStartsOn: 1,
        });
  const endDate = endOfWeek(currentTab === "month" ? monthEnd : currentMonth, {
    weekStartsOn: 1,
  });
  const { data: events, fetchStatus } = useQuery({
    queryKey: ["event", startDate, endDate, filter, searchValue],
    queryFn: () =>
      fetchEvents({
        start: new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          0,
          0,
          0,
          0
        ).valueOf(),
        end: new Date(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          23,
          59,
          59,
          999
        ).valueOf(),
        token,
        eventFilter: filter,
        isPublished: true,
        searchQuery: searchValue
      }),
  });

  const dayFormat = "dd";
  const rows: JSX.Element[] = [];
  let day = startDate;
  let formattedDate = "";
  let days: JSX.Element[] = [];

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, dayFormat);
      days.push(
        currentTab === "month" ? (
          <td className="customScheduleDay-container">
            <CustomScheduleDay
              day={`${formattedDate}`}
              dayIsToday={isToday(day)}
              disabled={!isSameMonth(day, monthStart)}
              key={`${formattedDate}`}
              events={events?.events
                ?.filter((event) => {
                  return (
                    event.startDate.slice(0, event.startDate.indexOf("T")) ===
                    moment(day)
                      .utcOffset(0, true)
                      .toISOString()
                      .slice(
                        0,
                        moment(day)
                          .utcOffset(0, true)
                          .toISOString()
                          .indexOf("T")
                      )
                  );
                })
                .map((item) => ({
                  slug: item.slug,
                  name: item.name,
                  startTime: item.startTime,
                  color: item.color,
                }))}
              isLoading={fetchStatus === "fetching" && !events}
              setIsReviewEventModalOpen={setIsReviewEventModalOpen}
            />
          </td>
        ) : currentTab === "week" ? (
          <DaySchedule
            events={
              events?.events
                ?.filter((event) => {
                  return (
                    event.startDate.slice(0, event.startDate.indexOf("T")) ===
                    moment(day)
                      .utcOffset(0, true)
                      .toISOString()
                      .slice(
                        0,
                        moment(day)
                          .utcOffset(0, true)
                          .toISOString()
                          .indexOf("T")
                      )
                  );
                })
                .map((item) => ({
                  name: item.name,
                  startTime: getTimeFromMinutes(item.startTime),
                  endTime: item.endTime
                    ? getTimeFromMinutes(item.endTime)
                    : undefined,
                })) || []
            }
            key={`${formattedDate}`}
          />
        ) : (
          <DaySchedule
            events={
              events?.events
                ?.filter((event) => {
                  return (
                    event.startDate.slice(0, event.startDate.indexOf("T")) ===
                    moment(day)
                      .utcOffset(0, true)
                      .toISOString()
                      .slice(
                        0,
                        moment(day)
                          .utcOffset(0, true)
                          .toISOString()
                          .indexOf("T")
                      )
                  );
                })
                .map((item) => ({
                  name: item.name,
                  startTime: getTimeFromMinutes(item.startTime),
                  endTime: item.endTime
                    ? getTimeFromMinutes(item.endTime)
                    : undefined,
                })) || []
            }
            key={`${formattedDate}`}
          />
        )
      );
      if (currentTab === "day") {
        break;
      }
      day = addDays(day, 1);
    }

    rows.push(
      <tr
        className={`${
          currentTab === "day"
            ? "flex items-center justifyCenter fullWidth"
            : "calendarSchedule-table-grid"
        } ${
          currentTab === "month"
            ? "calendarSchedule-table-grid-days"
            : "daySchedule"
        }`}
        key={`${formattedDate}`}
      >
        {days.map((i) => i)}
      </tr>
    );
    if (currentTab === "day") {
      break;
    }
    days = [];
  }

  return (
    <tbody className="calendarSchedule-table-body">{rows.map((i) => i)}</tbody>
  );
}

function RenderHeader(
  currentMonth: Date,
  filter: EventsFilter,
  currentTab: "month" | "week" | "day"
) {
  const startDate =
    currentTab === "day"
      ? currentMonth
      : startOfWeek(currentMonth, { weekStartsOn: 1 });
  const endDate = endOfWeek(currentMonth, { weekStartsOn: 1 });
  const dayFormat = "dd";
  const dayToStringFormat = "eee";
  let day = startDate;
  let days: JSX.Element[] = [];
  let formattedDate;
  if (currentTab === "week") {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, dayFormat);
      days.push(
        <th key={`${day}`}>
          <div>
            <p className="calendarSchedule-table-body-day">
              {format(day, dayToStringFormat)}
            </p>
            <p className="calendarSchedule-table-body-day">{formattedDate}</p>
          </div>
        </th>
      );
      day = addDays(day, 1);
    }
  }

  return (
    <thead
      className={`calendarSchedule-table-header positionRelative ${
        currentTab === "month"
          ? "calendarSchedule-table-header-month"
          : "calendarSchedule-table-header"
      }`}
    >
      <tr
        className={`${
          currentTab === "day"
            ? "flex items-center justifyCenter fullWidth"
            : "calendarSchedule-table-grid"
        }`}
      >
        {currentTab === "month" ? (
          <>
            <th className="calendarSchedule-table-body-day">Mon</th>
            <th className="calendarSchedule-table-body-day">Tue</th>
            <th className="calendarSchedule-table-body-day">Wed</th>
            <th className="calendarSchedule-table-body-day">Thur</th>
            <th className="calendarSchedule-table-body-day">Fri</th>
            <th className="calendarSchedule-table-body-day">Sat</th>
            <th className="calendarSchedule-table-body-day">Sun</th>
          </>
        ) : currentTab === "week" ? (
          <>{days.map((day) => day)}</>
        ) : (
          <th>
            <div>
              <p className="calendarSchedule-table-body-day">
                {format(day, dayToStringFormat)}
              </p>
              <p className="calendarSchedule-table-body-day">
                {format(day, dayFormat)}
              </p>
            </div>
          </th>
        )}
      </tr>
    </thead>
  );
}

interface Props {
  currentMonth: Date;
  filter: EventsFilter;
  currentTab: "month" | "week" | "day";
  searchValue: string,
}

export default function CalendarSchedule({
  currentMonth,
  filter,
  currentTab,
  searchValue
}: Props) {
  const dateFormat = "MMMM yyyy";
  const [isReviewEventModalOpen, setIsReviewEventModalOpen] = useState("");

  return (
    <div className="calendarSchedule">
      <header className="calendarSchedule-month">
        {format(currentMonth, dateFormat)}
      </header>
      <main>
        {isReviewEventModalOpen.length ? (
          <ReviewEventDetailsModal
            slug={isReviewEventModalOpen}
            closeModal={() => setIsReviewEventModalOpen("")}
          />
        ) : null}
        {currentTab === "month" ? (
          <table className="calendarSchedule-table">
            {RenderHeader(currentMonth, filter, currentTab)}
            {RenderCells(
              currentMonth,
              filter,
              currentTab,
              searchValue,
              setIsReviewEventModalOpen,
            )}
          </table>
        ) : (
          <div className="flex flex-row gap-20 items-center fullHeight">
            <div className="flex flex-column spaceBetween fullHeight">
              <div className="daySchedule-hour">8 AM</div>
              <div className="daySchedule-hour">9 AM</div>
              <div className="daySchedule-hour">10 AM</div>
              <div className="daySchedule-hour">11 AM</div>
              <div className="daySchedule-hour">12 PM</div>
              <div className="daySchedule-hour">1 PM</div>
              <div className="daySchedule-hour">2 PM</div>
              <div className="daySchedule-hour">3 PM</div>
              <div className="daySchedule-hour">4 PM</div>
              <div className="daySchedule-hour">5 PM</div>
              <div className="daySchedule-hour">6 PM</div>
              <div className="daySchedule-hour">7 PM</div>
              <div className="daySchedule-hour">8 PM</div>
              <div className="daySchedule-hour">9 PM</div>
              <div className="daySchedule-hour">10 PM</div>
              <div className="daySchedule-hour">11 PM</div>
              <div className="daySchedule-hour">12 AM</div>
              <div className="daySchedule-hour">1 AM</div>
              <div className="daySchedule-hour">2 AM</div>
              <div className="daySchedule-hour">3 AM</div>
              <div className="daySchedule-hour">4 AM</div>
              <div className="daySchedule-hour">5 AM</div>
              <div className="daySchedule-hour">6 AM</div>
              <div className="daySchedule-hour">7 AM</div>
            </div>
            <table className="calendarSchedule-table fullWidth">
              {RenderHeader(currentMonth, filter, currentTab)}
              {RenderCells(
                currentMonth,
                filter,
                currentTab,
                searchValue,
                setIsReviewEventModalOpen
              )}
            </table>
          </div>
        )}
      </main>
    </div>
  );
}
