import { TState } from "./slice.types";

const initialState: TState = {
  email: '',
  fullName: '',
  profileImage: '',
  completeTutorial: false,
  isDemoNotificationDismissed: false,
};

export { initialState };