import React, { useEffect, useState } from "react";
import ReviewSelectionsModal from "../../../components/Modal/ReviewSelectionsModal";
import fileCheckIcon from "../../../assets/images/file-check-02.png";
import { Button } from "../../../components/Buttons/Button";
import { loadUserSelections } from "../handlers/loadUserSelections";
import { fetchMeals } from "../../../httpQueries/http";
import { MealBlockChoice } from "../../../types/meals";
import Loader from "../../../components/Loader";
import { TodoItem, TodoItemType } from "../../../types/todoItem";

interface Props {
  title: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  restaurantInfo: {
    name: string;
    address: string;
    cuisines: string;
    distance: string;
  };
  openMealEdit: (mealId: string) => void;
  menu: {
    name: string;
    subcategory_id: string;
    menu_item_list: {
      count: number;
      name: string;
      price: number;
      qty_available: null;
      unit_size: null;
      unit_of_measurement: string;
      description: string;
      is_available: boolean;
      image: string;
      customizations: [
        {
          name: string;
          min_choice_options: number;
          max_choice_options: number;
          options: [
            {
              name: string;
              price: number;
              customizations: any[];
              min_qty: number;
              max_qty: number;
              conditional_price: {};
              formatted_price: string;
              default_qty: number;
              option_id: string;
            }
          ];
          customization_id: string;
        }
      ];
      min_price: number;
      original_price: number;
      formatted_price: string;
      attributes: [];
      product_id: string;
      thumbnail_image: string;
      should_fetch_customizations: boolean;
      supports_image_scaling: boolean;
    }[];
  }[];
  setPreOrder: React.Dispatch<
    React.SetStateAction<
      {
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number;
      }[]
    >
  >;
  mealBlockId: number | undefined;
  setOrders: React.Dispatch<
    React.SetStateAction<
      {
        agendaBlockId: number;
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number;
      }[]
    >
  >;
  agendaBlockId: number;
  orders: {
    agendaBlockId: number;
    customizations: {
      customizationId: string;
      optionId: string[];
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  }[];
  setShowSelectRestaurantModal: React.Dispatch<React.SetStateAction<boolean>>;
  todoItems:
    | {
        todoItems: TodoItem[];
        total: number;
      }
    | undefined;
  eventId: number,
}

export default function ReviewSelectionsInviteeView({
  title,
  setIsOpen,
  restaurantInfo,
  openMealEdit,
  mealBlockId,
  menu,
  setOrders,
  setPreOrder,
  agendaBlockId,
  orders,
  setShowSelectRestaurantModal,
  todoItems,
  eventId
}: Props) {
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(false);
  const [isOrganizerOrder, setIsOrganizerOrder] = useState(false);

  useEffect(() => {
    if (mealBlockId) {
      setIsLoading(true);
      fetchMeals({
        id: mealBlockId,
        token,
      }).then((res) =>
        setIsOrganizerOrder(res?.mealBlock.choice === MealBlockChoice.Organizer)
      );
      loadUserSelections({
        mealBlockId,
        menu,
        setOrders,
        setPreOrder,
        agendaBlockId: mealBlockId,
      }).then(() => {
        setIsLoading(false)
      });
    }
  }, []);

  return (
    <ReviewSelectionsModal title={title} setIsOpen={setIsOpen}>
      {isLoading ? (
        <div className="flex fullWidth justifyCenter items-center">
          <Loader size={"lg"} scale="1.5" />
        </div>
      ) : isOrganizerOrder ? (
        <p className="text-sm font-regular color-gray-800">
          The organizer has already placed the order, you are not able to make
          any changes at the moment
        </p>
      ) : (
        <div className="gap-16 flex flex-column">
          <div className="flex flex-column gap-6">
            <h5 className="text-lg font-semibold color-gray-950">
              {restaurantInfo.name}
            </h5>
            <p className="text-sm font-regular color-gray-900">
              {restaurantInfo.address} • {restaurantInfo.distance} •{" "}
              {restaurantInfo.cuisines}
            </p>
          </div>
          <div className="flex flex-column gap-16">
            <div className="flex flex-column gap-16">
              {orders
                .filter((order) => order.agendaBlockId === mealBlockId)
                .map((order) => (
                  <div className="flex flex-column gap-16">
                    <div className="flex spaceBetween gap-6">
                      <div className="flex flex-column gap-4">
                        <h6 className="text-lg font-semibold color-gray-950">
                          {menu.map(
                            (customization) =>
                              customization.menu_item_list.find(
                                (item) => item.product_id === order.productId
                              )?.name
                          )}
                        </h6>
                        <p className="text-lg font-regular color-gray-950">
                          {order.notes}
                        </p>
                      </div>
                      <p className="text-lg font-semibold color-gray-950">
                        X{order.count}
                      </p>
                    </div>
                    <div className="restaurantDetails-info-restaurant-notesButton w-fit">
                      <img src={fileCheckIcon} alt="" />
                      <p
                        className="text-xs font-semibold color-gray-950"
                        onClick={() => {
                          openMealEdit(order.productId);
                        }}
                      >
                        Notes
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="fullWidth flex gap-8 justifyEnd items-center mt-32">
            <Button
              buttonType="regular"
              hierarchy="newDesign-primary"
              size="md"
              contentCenter
              onClick={() => {
                setShowSelectRestaurantModal(true);
                setIsOpen(false)
              }}
              newDesignPaddingNone
            >
              <p className="px-5">Edit food order</p>
            </Button>
          </div>
        </div>
      )}
    </ReviewSelectionsModal>
  );
}
