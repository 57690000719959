import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { initialState } from "./slice.init";
import { TCurrentRoutePayload } from "./slice.types";

export const slice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<TCurrentRoutePayload>) => {
      state.email = action.payload.email;
      state.fullName = action.payload.fullName;
      state.profileImage = action.payload.profileImage;
      state.completeTutorial = action.payload.completeTutorial
      state.isDemoNotificationDismissed = action.payload.isDemoNotificationDismissed
    }
  },
});
