import { useWindowSize } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import EventDetails from "./components/EventDetails";
import EventAgenda from "./components/EventAgenda";
import { EventInfoProvider } from "../../utilities/eventInfoContext";
import EventInviteTeam from "./components/EventInviteTeam";
import EventReviewAndSubmit from "./components/EventReviewAndSubmit";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../utilities/supabaseClient";
import { createPortal } from "react-dom";
import ModalNotification from "../../components/Modal/ModalNotification";

interface Props {
  type: "Details" | "Agenda" | "InviteTeam" | "Review";
  isEdit?: boolean;
}

export default function EventPage({ type, isEdit }: Props) {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [modalNotificationIsOpen, setModalNotificationIsOpen] = useState<{
    name: string;
    description: string;
    firstButtonName: string;
    secondButtonName: string;
    onFirstButtonClick: () => void;
    onSecondButtonClick: () => void;
  } | null>(null);
  const [subBlocks, setSubBlocks] = useState<
  {
    id: string;
    name: string;
    startTime: string;
    endTime: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    } | null;
    index: number;
    docsId: number[];
    agendaItemId: string;
  }[]
  >([]); 
  const navigate = useNavigate();
  useEffect(() => {
    const token = supabase()
      .auth.getSession()
      .then((response) => {
        if (!response.data.session?.access_token) {
          navigate("/login");
        }
      });
  }, []);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  return (
    <div
      className={`eventDetails_container ${
        type === "Agenda" ? "eventDetails_container-agenda" : ""
      }`}
    >
      {modalNotificationIsOpen
        ? createPortal(
            <ModalNotification
              setIsOpen={setModalNotificationIsOpen}
              name={modalNotificationIsOpen.name}
              description={modalNotificationIsOpen.description}
              onFirstButton={modalNotificationIsOpen.onFirstButtonClick}
              onSecondButton={modalNotificationIsOpen.onSecondButtonClick}
              firstButtonName={modalNotificationIsOpen.firstButtonName}
              secondButtonName={modalNotificationIsOpen.secondButtonName}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      {/* {sizeIsDesktop
        ? <SideBarNavigationNew
            hierarchy='primary'
            currentItem="Home"
          />
        : null
      } */}
      <div className="eventDetails_main-container eventDetails_main-container-noMaxWidth">
        <EventInfoProvider>
          {type === "Details" ? (
            <EventDetails
              isDesktopSize={sizeIsDesktop}
              isEdit={isEdit}
            />
          ) : null}

          {type === "Agenda" ? (
            <EventAgenda
              isDesktopSize={sizeIsDesktop}
              subBlocks={subBlocks}
              setSubBlocks={setSubBlocks}
              isEditActions={isEdit}
            />
          ) : null}

          {type === "InviteTeam" ? (
            <EventInviteTeam
              isDesktopSize={sizeIsDesktop}
              isEdit={isEdit}
            />
          ) : null}

          {type === "Review" ? (
            <EventReviewAndSubmit
              isDesktopSize={sizeIsDesktop}
              isEdit={isEdit}
              subBlocks={subBlocks}
              setSubBlocks={setSubBlocks}
            />
          ) : null}
        </EventInfoProvider>
      </div>
    </div>
  );
}
