import React from "react";

interface Props {
  children: React.ReactNode;
  bigPadding?: boolean;
  width?: string,
}

export default function UsersThatMadeOrderCountContainer({
  children,
  bigPadding,
  width
}: Props) {
  return (
    <div
      className={`
        eventDetailsWelcomePage-thingsTodo-todo-message 
        eventDetailsWelcomePage-thingsTodo-todo-message-blue 
        ${bigPadding ? 'px-12 py-12' : ''}
      `}
      style={{
        width
      }}
    >
      {children}
    </div>
  );
}
