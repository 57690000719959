import { useWindowSize } from "@uidotdev/usehooks";
import React, { useEffect, useState } from "react";
import SideBarNavigationNew from "../../components/Navigation/SideBarNavigationNew";
import ContactBlock from "../../components/Blocks/ContactBlock";
import messageIcon from "../../assets/images/message-chat-circle.svg";
import { useQuery } from "@tanstack/react-query";
import { fetchSupportInfoData } from "../../httpQueries/http";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../utilities/supabaseClient";
import BottomNavigation from "../../components/Navigation/BottomNavigation";

export function Support() {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const navigate = useNavigate();

  const token = window.localStorage.getItem("token");

  const { data } = useQuery({
    queryKey: ["support data", token],
    queryFn: () => fetchSupportInfoData({ token }),
  });

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  if (!token?.length) {
    supabase().auth.signOut();
    localStorage.clear();
    localStorage.clear();
    
    navigate("/login");

    return null;
  }

  return (
    <div
      className={`eventDetails_container ${
        !sizeIsDesktop ? "flex-column" : ""
      } fullHeight`}
    >
      <SideBarNavigationNew hierarchy="primary" currentItem="Support" />
      {!sizeIsDesktop ? (
        <div className="flex flex-column bgWhite px-24 py-24">
          <h3 className="eventsPage-header-title">Support</h3>
          <p className="text-md font-regular color-gray-600">
            Our friendly team is always here to chat.
          </p>
        </div>
      ) : null}
      <div
        className="eventDetails_main-container fullWidth border-box"
        style={{
          padding: sizeIsDesktop ? "140px 12px 0" : "15px 12px 0",
          margin: sizeIsDesktop ? "0" : undefined,
          marginBottom: sizeIsDesktop ? undefined : "auto",
          border: "none",
          marginTop: "0",
        }}
      >
        {sizeIsDesktop ? (
          <div
            className={`eventDetails_main-header px-32 positionAbsolute fullWidth`}
            style={{ left: "112px" }}
          >
            <div
              className="eventsPage-header"
              style={{
                margin: "0",
                marginLeft: sizeIsDesktop ? "130px" : undefined,
              }}
            >
              <div className="eventsPage-header-info">
                <h3 className="eventsPage-header-title">Support</h3>
                <p>Our friendly team is always here to chat.</p>
              </div>
            </div>
          </div>
        ) : null}
        <div
          className={`flex gap-24 halfWidth ${
            sizeIsDesktop ? "flex-row" : "flex-column fullWidth"
          }`}
          style={{ marginLeft: sizeIsDesktop ? "130px" : undefined }}
        >
          <ContactBlock
            icon={messageIcon}
            title="Let us know how we can help."
            description="Send us an email and we'll get right back to you!"
            action="email"
            actionText={data?.Email || ""}
          />
        </div>
      </div>
      <BottomNavigation selected="none" />
    </div>
  );
}
