import React, { useContext, useEffect, useState } from "react";
import googleIcon from "../../assets/images/googleIcon.svg";
import logo from "../../assets/images/logo-lg-color.svg";
import { FormInput } from "../../components/Inputs/FormInput";
import { Button } from "../../components/Buttons/Button";
import { useWindowSize } from "@uidotdev/usehooks";
import avatar from "../../assets/images/Avatar-olivia.svg";
import { useDispatch } from "../../store/hooks";
import { currentUserActions } from "../../store/currentUser";
import { useNavigate, useSearchParams } from "react-router-dom";
import { supabase } from "../../utilities/supabaseClient";
import ErrorMessage from "../../components/errorMessage/errorMessage";
import Loader from "../../components/Loader";
import { fetchMyData } from "../../httpQueries/http";
import { validateEmail } from "../../utilities/validateEmail";
import { checkIsPasswordHasSpecialCharacter } from "../../utilities/passwordHasSpecialCharacter";
import { RSVPEventValue } from "../../utilities/RSVPEventContext";

export default function Login() {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailError, setIsEmailError] = useState({
    message: "",
    isError: false,
  });
  const [isPasswordError, setIsPasswordError] = useState({
    message: "",
    isError: false,
  });
  const [params] = useSearchParams();
  const { setEventSlug } = useContext(RSVPEventValue);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  async function LoginUser() {
    if (validateEmail(userEmail.trim())) {
      setIsEmailError({ message: "", isError: false });
    }

    if (userEmail.trim().length) {
      setIsEmailError({ message: "", isError: false });
    }

    if (checkIsPasswordHasSpecialCharacter(userPassword.trim())) {
      setIsPasswordError({ message: "", isError: false });
    }

    if (userPassword.trim().length >= 8) {
      setIsPasswordError({ message: "", isError: false });
    }

    if (
      userEmail.trim().length &&
      userPassword.trim().length >= 8 &&
      validateEmail(userEmail.trim()) &&
      checkIsPasswordHasSpecialCharacter(userPassword.trim())
    ) {
      setIsEmailError({ message: "", isError: false });
      setIsPasswordError({ message: "", isError: false });

      setIsLoading(true);
      const { data, error } = await supabase().auth.signInWithPassword({
        email: userEmail.trim(),
        password: userPassword.trim(),
      });

      if (error) {
        setIsError(true);
        setIsLoading(false);

        return;
      }

      if (!error) {
        setIsError(false);
      }

      const token = data.session?.access_token;

      if (token) {
        const response = await fetchMyData({ token });

        if (response) {
          dispatch(
            currentUserActions.setCurrentUser({
              fullName: response.fullName,
              email: response.email,
              profileImage: avatar,
              completeTutorial: response.isTutorialCompleted,
              isDemoNotificationDismissed: response.isDemoNotificationDismissed
            })
          );

          localStorage.setItem("token", token);
          localStorage.setItem("lastTokenSetTime", new Date().toISOString());

          navigate("/");
        }
      }
    }

    if (!validateEmail(userEmail.trim())) {
      setIsEmailError({
        message: "Please, enter correct email.",
        isError: true,
      });
    }

    if (!userEmail.trim().length) {
      setIsEmailError({ message: "Please, enter an email.", isError: true });
    }

    if (!checkIsPasswordHasSpecialCharacter(userPassword.trim())) {
      setIsPasswordError({
        message: "Password must contain one special character.",
        isError: true,
      });
    }

    if (userPassword.trim().length < 8) {
      setIsPasswordError({
        message: "Password must have at least 8 characters.",
        isError: true,
      });
    }
  }

  async function LoginUserByGoogle() {
    setIsLoading(true);
    const res = await supabase()
      .auth.signInWithOAuth({
        provider: "google",
      })
      .then(() => {
        sessionStorage.setItem("googleSignUpWorks", "true");
      });
  }

  useEffect(() => {
    setEventSlug(params.get("slug") ?? "");
  }, []);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  useEffect(() => {
    window.addEventListener("keyup", async (e) => {
      if (e.key === "Enter") {
        await LoginUser();
      }
    });

    return () => window.removeEventListener("keyup", LoginUser);
  }, [userEmail, userPassword]);

  return (
    <div className="login">
      <div className="login-signIn">
        <div className="login-signIn-header">
          <img src={logo} alt="" className="login-signIn-logo" />
        </div>
        <div className="login-signIn-content">
          <div className="flex gap-12 flex-column selfStart">
            <h3 className="login-signIn-title">Welcome back</h3>
            <p className="login-signIn-description">
              Welcome back! Please enter your details.
            </p>
          </div>
          {isError ? (
            <ErrorMessage
              title="Invalid email or password"
              description="The info you entered doesn't match our records. Try again!"
            />
          ) : null}
          <div className="flex flex-column gap-24 fullWidth">
            <div className="flex gap-20 flex-column">
              <FormInput
                type="Email"
                label="Email"
                design="new"
                fullWidth
                value={userEmail}
                isError={isEmailError.isError}
                errorMessage={isEmailError.message}
                onChange={(e) => setUserEmail(e.target.value)}
              />
              <FormInput
                design="new"
                type="Password"
                label="Password"
                value={userPassword}
                isError={isPasswordError.isError}
                errorMessage={isPasswordError.message}
                onChange={(e) => setUserPassword(e.target.value)}
              />
            </div>
            <Button
              buttonType="regular"
              hierarchy="linkColor"
              size="md"
              paddingNone
              onClick={() => navigate("/resetPassword")}
            >
              Forgot password
            </Button>
            {isLoading ? (
              <div className="flex justifyCenter fullWidth">
                <Loader size="sm" scale="2.5" />
              </div>
            ) : (
              <div className="flex flex-column gap-16">
                <Button
                  buttonType="regular"
                  hierarchy="newDesign-primary"
                  size="lg"
                  newDesignPaddingNone
                  fullWidth
                  contentCenter
                  asyncOnClick={LoginUser}
                >
                  Sign in
                </Button>
                <Button
                  buttonType="regular"
                  hierarchy="newDesign-terciary"
                  newDesignPaddingNone
                  size="lg"
                  fullWidth
                  contentCenter
                  asyncOnClick={LoginUserByGoogle}
                >
                  <div className="flex gap-12">
                    <img src={googleIcon} alt="" />
                    <p>Sign in with Google</p>
                  </div>
                </Button>
              </div>
            )}
          </div>
          <div className="flex gap-4">
            <p className="whiteSpaceNoWrap">Don’t have an account?</p>
            <Button
              buttonType="regular"
              hierarchy="linkColor"
              size="md"
              paddingNone
              onClick={() => (!isLoading ? navigate("/SignUp") : () => {})}
            >
              <p className="whiteSpaceNoWrap">Sign up</p>
            </Button>
          </div>
        </div>
        <p className="login-signIn-copyright">© Gatherwise Inc {new Date().getFullYear()}</p>
      </div>
      {sizeIsDesktop ? (
        <div className="login-bgImage">
          <p className="login-signIn-copy">Meet better.</p>
        </div>
      ) : null}
    </div>
  );
}
