import React, { useState } from "react";
import MenuItem from "./MenuItem";
import arrowLeft from "../../../assets/images/arrow-narrow-left.svg";
import Loader from "../../../components/Loader";
import CategoryTabs from "./CategoryTabs";
import CartDetails from "../../../components/MealSelection/CartDetails";
import { Button } from "../../../components/Buttons/Button";

interface Props {
  id: string;
  name: string;
  setShowSelectRestaurantModal: React.Dispatch<React.SetStateAction<boolean>>;
  menu: {
    name: string;
    subcategory_id: string;
    menu_item_list: {
      count: number;
      name: string;
      price: number;
      qty_available: null;
      unit_size: null;
      unit_of_measurement: string;
      description: string;
      is_available: boolean;
      image: string;
      customizations: [
        {
          name: string;
          min_choice_options: number;
          max_choice_options: number;
          options: [
            {
              name: string;
              price: number;
              customizations: any[];
              min_qty: number;
              max_qty: number;
              conditional_price: {};
              formatted_price: string;
              default_qty: number;
              option_id: string;
            }
          ];
          customization_id: string;
        }
      ];
      min_price: number;
      original_price: number;
      formatted_price: string;
      attributes: [];
      product_id: string;
      thumbnail_image: string;
      should_fetch_customizations: boolean;
      supports_image_scaling: boolean;
    }[];
  }[];
  setOrderedMeals: React.Dispatch<
    React.SetStateAction<
      {
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number,
      }[]
    >
  >;
  orderedMeals: {
    customizations: {
      customizationId: string;
      optionId: string[];
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number,
  }[];
  setOrders: React.Dispatch<
    React.SetStateAction<
      {
        agendaBlockId: number;
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number;
      }[]
    >
  >;
  orders: {
    agendaBlockId: number;
    customizations: {
      customizationId: string;
      optionId: string[];
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  }[];
  selectedRestaurant: {
    id: string | number;
    name: string;
    logo: string;
    status?: "Open" | "Close";
    address: string;
    distance?: number;
    cuisines?: string[];
    description: string;
  } | null;
  agendaBlockId: number;
  isDesktopSize: boolean;
  asyncOnAddMeal: () => Promise<void>;
  showSelectMealModal: boolean;
  showSelectCustomizationModal: string,
  setShowSelectCustomizationModal: React.Dispatch<React.SetStateAction<string>>,
  setReviewUserSelectionsModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export default function RestaurantMenu({
  setShowSelectRestaurantModal,
  menu,
  orderedMeals,
  setOrderedMeals,
  setOrders,
  orders,
  selectedRestaurant,
  agendaBlockId,
  isDesktopSize,
  asyncOnAddMeal,
  showSelectMealModal,
  showSelectCustomizationModal,
  setShowSelectCustomizationModal,
  setReviewUserSelectionsModalIsOpen
}: Props) {
  const [selectedCategory, setSelectedCategory] = useState<null | string>(null);
  const [currentView, setCurrentView] = useState<1 | 2>(1);

  return (
    <div className="restaurantMenu">
      <div className="fullHeight selectRestaurant-withoutPadding">
        <header className="selectRestaurant-main-header">
          <div
            className="flex flex-row gap-12 items-center border-gray-200 fullWidth"
            style={{
              position: "relative",
              zIndex: "30",
              padding: "0 0 24px 24px",
            }}
          >
            <img
              className="selectRestaurant-main-info-backButton"
              src={arrowLeft}
              alt=""
              onClick={() => setShowSelectRestaurantModal(false)}
            />
            <p className="text-xl font-semibold color-gray-900">
              Back to agenda block
            </p>
          </div>
        </header>
        <main className={`restaurantMenu-main`} style={{ paddingBottom: isDesktopSize ? '100px' : '165px' }}>
          {isDesktopSize || currentView === 1 ? (
            <>
              <CategoryTabs
                categories={menu.map((category) => ({
                  name: category.name,
                  items: category.menu_item_list.length,
                  isSelected: selectedCategory === category.name,
                  onSelect: () => setSelectedCategory(category.name),
                }))}
              />
              <div className="restaurantMenu-main-menu overflowYScroll grid-rows-auto">
                {menu.length > 0 ? (
                  selectedCategory ? (
                    menu.map((category) => {
                      if (category.name !== selectedCategory) {
                        return null;
                      }

                      return (
                        <React.Fragment key={category.subcategory_id}>
                          {category.menu_item_list.map((menuItem) => {
                            return (
                              <MenuItem
                                key={menuItem.product_id}
                                name={menuItem.name}
                                description={menuItem.description}
                                price={menuItem.formatted_price}
                                image={menuItem.image}
                                count={
                                  orders.find(
                                    (order) =>
                                      order.productId === menuItem.product_id
                                  )?.count || 0
                                }
                                categoryId={category.subcategory_id}
                                productId={menuItem.product_id}
                                orderedMeals={orderedMeals}
                                setOrderedMeals={setOrderedMeals}
                                markedPrice={menuItem.price}
                                setOrders={setOrders}
                                orders={orders}
                                setReviewUserSelectionsModalIsOpen={setReviewUserSelectionsModalIsOpen}
                                agendaBlockId={agendaBlockId}
                                startShowSelectCustomizationModal={
                                  showSelectCustomizationModal
                                }
                                setStartShowSelectCustomizationModal={
                                  setShowSelectCustomizationModal
                                }
                                asyncOnAddMeal={asyncOnAddMeal}
                              />
                            );
                          })}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    menu.map((category) => (
                      <React.Fragment key={category.subcategory_id}>
                        {category.menu_item_list.map((menuItem) => {
                          return (
                            <MenuItem
                              key={menuItem.product_id}
                              name={menuItem.name}
                              description={menuItem.description}
                              price={menuItem.formatted_price}
                              image={menuItem.image}
                              count={
                                orders.find(
                                  (order) =>
                                    order.productId === menuItem.product_id
                                )?.count || 0
                              }
                              categoryId={category.subcategory_id}
                              productId={menuItem.product_id}
                              orderedMeals={orderedMeals}
                              setOrderedMeals={setOrderedMeals}
                              markedPrice={menuItem.price}
                              setOrders={setOrders}
                              orders={orders}
                              agendaBlockId={agendaBlockId}
                              setReviewUserSelectionsModalIsOpen={setReviewUserSelectionsModalIsOpen}
                              startShowSelectCustomizationModal={
                                showSelectCustomizationModal
                              }
                              setStartShowSelectCustomizationModal={
                                setShowSelectCustomizationModal
                              }
                              asyncOnAddMeal={asyncOnAddMeal}
                            />
                          );
                        })}
                      </React.Fragment>
                    ))
                  )
                ) : (
                  <div className="flex items-center fullWidth justifyCenter">
                    <Loader size="xl" />
                  </div>
                )}
              </div>
            </>
          ) : null}
          {isDesktopSize || currentView === 2 ? (
            <CartDetails
              name={selectedRestaurant?.name || "Restaurant name example"}
              status={selectedRestaurant?.status}
              address={selectedRestaurant?.address}
              distance={selectedRestaurant?.distance}
              cuisines={selectedRestaurant?.cuisines || []}
              fee={0}
              total={0}
              logo={""}
              description={selectedRestaurant?.description}
              creatorIsOrder={false}
              menu={menu}
              orderedMeals={orders.map((order) => {
                let product:
                  | {
                      count: number;
                      name: string;
                      price: number;
                      qty_available: null;
                      unit_size: null;
                      unit_of_measurement: string;
                      description: string;
                      is_available: boolean;
                      image: string;
                      customizations: [
                        {
                          name: string;
                          min_choice_options: number;
                          max_choice_options: number;
                          options: [
                            {
                              name: string;
                              price: number;
                              customizations: any[];
                              min_qty: number;
                              max_qty: number;
                              conditional_price: {};
                              formatted_price: string;
                              default_qty: number;
                              option_id: string;
                            }
                          ];
                          customization_id: string;
                        }
                      ];
                      min_price: number;
                      original_price: number;
                      formatted_price: string;
                      attributes: [];
                      product_id: string;
                      thumbnail_image: string;
                      should_fetch_customizations: boolean;
                      supports_image_scaling: boolean;
                    }
                  | undefined;

                menu.map((category) => {
                  category.menu_item_list.map((item) => {
                    if (item.product_id === order.productId) {
                      product = item;
                    }
                  });
                });

                const productToOrder = orders.find(
                  (item) => item.productId === order?.productId
                );

                return {
                  img: product?.image || "",
                  title: product?.name || "",
                  notes: productToOrder?.notes || "",
                  price: product?.formatted_price || "",
                  count: order.count,
                  id: product?.product_id || "",
                  onIncrease: () => {
                    setOrders((prevState) => {
                      const productToOrder = prevState.find(
                        (item) => item.productId === order?.productId
                      );
                      const productToOrderIndex = prevState.findIndex(
                        (item) => item.productId === order?.productId
                      );

                      if (!!productToOrder) {
                        return [
                          ...prevState.slice(0, productToOrderIndex),
                          {
                            ...productToOrder,
                            count: productToOrder.count + 1,
                          },
                          ...prevState.slice(productToOrderIndex + 1),
                        ];
                      }

                      return prevState;
                    });
                  },
                  onDecrease: () => {
                    setOrders((prevState) => {
                      const productToOrder = prevState.find(
                        (item) => item.productId === order.productId
                      );
                      const productToOrderIndex = prevState.findIndex(
                        (item) => item.productId === order?.productId
                      );

                      if (!!productToOrder) {
                        if (productToOrder.count === 1) {
                          return [
                            ...prevState.filter(
                              (item) => item.productId !== order?.productId
                            ),
                          ];
                        }
                        return [
                          ...prevState.slice(0, productToOrderIndex),
                          {
                            ...productToOrder,
                            count: productToOrder.count - 1,
                          },
                          ...prevState.slice(productToOrderIndex + 1),
                        ];
                      }

                      return prevState;
                    });
                  },
                  customizationsPrice: productToOrder?.customizations.map(customization => customization.markedPrice).reduce((previousValue, current) => previousValue + current, 0)
                };
              })}
              openMealEdit={setShowSelectCustomizationModal}
              withAddToOrder={asyncOnAddMeal}
              isLoading={false}
            />
          ) : null}
        </main>
        {!isDesktopSize ? (
          <footer
            className={`selectRestaurant-footer selectRestaurant-footer-attendee restaurantMenu-footer ${
              showSelectMealModal ? "positionFixed" : "positionRelative"
            } bottom0`}
          >
            {!isDesktopSize ? (
              currentView === 1 ? (
                <>
                  <Button
                    size="lg"
                    hierarchy={"newDesign-primary"}
                    newDesignPaddingNone
                    buttonType="regular"
                    onClick={() => {
                      setCurrentView(2);
                    }}
                    // disabled={!orderedMeals.length}
                  >
                    Review
                  </Button>
                </>
              ) : currentView === 2 ? (
                <>
                  <Button
                    size="lg"
                    hierarchy="newDesign-secondary"
                    newDesignPaddingNone
                    buttonType="regular"
                    onClick={() => setCurrentView(1)}
                  >
                    Edit order
                  </Button>
                  <Button
                    size="lg"
                    hierarchy={"newDesign-primary"}
                    newDesignPaddingNone
                    buttonType="regular"
                    onClick={() => {
                      asyncOnAddMeal();
                      setShowSelectRestaurantModal(false);
                    }}
                  >
                    Add meal
                  </Button>
                </>
              ) : null
            ) : null}
          </footer>
        ) : null}
      </div>
    </div>
  );
}
