import { createContext, useState } from "react";

export const MenuValue = createContext<{
  eventAgendaMealBlockMenu: {
    name: string;
    subcategory_id: string;
    menu_item_list: {
      count: number;
      name: string;
      price: number;
      qty_available: null;
      unit_size: null;
      unit_of_measurement: string;
      description: string;
      is_available: boolean;
      image: string;
      customizations: [
        {
          name: string;
          min_choice_options: number;
          max_choice_options: number;
          options: [
            {
              name: string;
              price: number;
              customizations: any[];
              min_qty: number;
              max_qty: number;
              conditional_price: {};
              formatted_price: string;
              default_qty: number;
              option_id: string;
            }
          ];
          customization_id: string;
        }
      ];
      min_price: number;
      original_price: number;
      formatted_price: string;
      attributes: [];
      product_id: string;
      thumbnail_image: string;
      should_fetch_customizations: boolean;
      supports_image_scaling: boolean;
    }[];
  }[];
  setEventAgendaMealBlockMenu: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        subcategory_id: string;
        menu_item_list: {
          count: number;
          name: string;
          price: number;
          qty_available: null;
          unit_size: null;
          unit_of_measurement: string;
          description: string;
          is_available: boolean;
          image: string;
          customizations: [
            {
              name: string;
              min_choice_options: number;
              max_choice_options: number;
              options: [
                {
                  name: string;
                  price: number;
                  customizations: any[];
                  min_qty: number;
                  max_qty: number;
                  conditional_price: {};
                  formatted_price: string;
                  default_qty: number;
                  option_id: string;
                }
              ];
              customization_id: string;
            }
          ];
          min_price: number;
          original_price: number;
          formatted_price: string;
          attributes: [];
          product_id: string;
          thumbnail_image: string;
          should_fetch_customizations: boolean;
          supports_image_scaling: boolean;
        }[];
      }[]
    >
  >;
}>({
  eventAgendaMealBlockMenu: [],
  setEventAgendaMealBlockMenu: () => {},
});

export function MenuContext({ children }: React.PropsWithChildren) {
  const [eventAgendaMealBlockMenu, setEventAgendaMealBlockMenu] = useState<
    {
      name: string;
      subcategory_id: string;
      menu_item_list: {
        count: number;
        name: string;
        price: number;
        qty_available: null;
        unit_size: null;
        unit_of_measurement: string;
        description: string;
        is_available: boolean;
        image: string;
        customizations: [
          {
            name: string;
            min_choice_options: number;
            max_choice_options: number;
            options: [
              {
                name: string;
                price: number;
                customizations: any[];
                min_qty: number;
                max_qty: number;
                conditional_price: {};
                formatted_price: string;
                default_qty: number;
                option_id: string;
              }
            ];
            customization_id: string;
          }
        ];
        min_price: number;
        original_price: number;
        formatted_price: string;
        attributes: [];
        product_id: string;
        thumbnail_image: string;
        should_fetch_customizations: boolean;
        supports_image_scaling: boolean;
      }[];
    }[]
  >([]);

  return (
    <MenuValue.Provider
      value={{
        eventAgendaMealBlockMenu,
        setEventAgendaMealBlockMenu,
      }}
    >
      {children}
    </MenuValue.Provider>
  );
}
