import React, { useState, useEffect, useContext } from "react";
import closeIcon from "../../assets/images/x-close-gray-lg.svg";
import announcementIcon from "../../assets/images/announcement-01.svg";
import { Button } from "../Buttons/Button";
import FeaturedIcon from "../FeaturedIcons/FeaturedIcon";
import presentationIcon from "../../assets/images/presentation-chart-01.svg";
import clockPlusIcon from "../../assets/images/clock-plus.svg";
import happyFaceIcon from "../../assets/images/face-happy.svg";
import { FormInput } from "../Inputs/FormInput";
import { EventValue } from "../../utilities/eventInfoContext";
import { AgendaBlockType } from "../../types/agendaBlockType";
import { Dropdown } from "../Dropdown/Dropdown";
import pencilIcon from "../../assets/images/pencil-02.svg";
import { useWindowSize } from "@uidotdev/usehooks";
import { createPortal } from "react-dom";
import SelectMeal from "../MealSelection/SelectMeals";
import { getTimeInMinutes } from "../../utilities/getTimeInMinutes";
import plusCircle from "../../assets/images/plus-circle-2.svg";
import DurationsDropdown from "../Dropdown/DurationsDropdown";
import AddSubBlockModal from "../AgendaSybBlocks/AddSubBlockModal";
import Loader from "../Loader";

const tabs: { id: number; name: string; type: AgendaBlockType }[] = [
  {
    id: 1,
    name: "Session",
    type: AgendaBlockType.Session,
  },
  {
    id: 2,
    name: "Break",
    type: AgendaBlockType.Break,
  },
  {
    id: 3,
    name: "Meal",
    type: AgendaBlockType.Meal,
  },
];

interface Props {
  longitude: number;
  latitude: number;
  location: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAgenda: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        icon: string;
        name: string;
        description: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        };
        startTime: string;
        endTime: string;
        type: AgendaBlockType;
        index: number;
        isEditable: boolean;
        finalizedUsersId?: number,
      }[]
    >
  >;
  agenda: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number;
    isEditable: boolean;
  }[];
  currentItem?: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number;
    isEditable: boolean;
  };
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isEdit: boolean;
  setMealSelectedOrders: React.Dispatch<
    React.SetStateAction<
      {
        restaurantId: string | number;
        mealAgendaItemId: string;
        orderedMeals: {
          customizations?: [
            {
              customizationId: string;
              optionId: string;
              markedPrice: number;
            }
          ];
          productId: string;
          markedPrice: number;
          notes: string;
        }[];
      }[]
    >
  >;
  dayId: number;
  setIsPickup: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        state: boolean;
      }[]
    >
  >;
  isPickup: {
    id: number;
    state: boolean;
  }[];
  setCreatorIsOrder: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        state: boolean | null;
      }[]
    >
  >;
  creatorIsOrder: {
    id: number;
    state: boolean | null;
  }[];
  selectedRestaurant: {
    id: string | number;
    name: string;
    logo: string;
    status?: "Open" | "Close";
    address: string;
    distance?: number;
    cuisines?: string[];
    menuLink?: string;
  } | null;
  setSelectedRestaurant: React.Dispatch<
    React.SetStateAction<{
      id: string | number;
      name: string;
      logo: string;
      status?: "Open" | "Close";
      address: string;
      distance?: number;
      cuisines?: string[];
      menuLink?: string;
    } | null>
  >;
  setOrders: React.Dispatch<
    React.SetStateAction<
      {
        agendaBlockId: number;
        customizations: {
          customizationId: string;
          optionId: string[];
          markedPrice: number;
        }[];
        productId: string;
        markedPrice: number;
        notes: string;
        count: number;
      }[]
    >
  >;
  orders: {
    agendaBlockId: number;
    customizations: {
      customizationId: string;
      optionId: string[];
      markedPrice: number;
    }[];
    productId: string;
    markedPrice: number;
    notes: string;
    count: number;
  }[];
  setSelectedAvailableMealsForAttendees: React.Dispatch<
    React.SetStateAction<
      { id: number; products: { id: string; name: string }[] }[]
    >
  >;
  selectedAvailableMealsForAttendees: {
    id: number;
    products: { id: string; name: string }[];
  }[];
  setMealBlocksWithoutMealsSelection: React.Dispatch<
    React.SetStateAction<string[]>
  >;
  agendaBlockId: number;
  restaurantsForOrders: {
    id: string | number;
    name: string;
    status?: "Open" | "Close" | undefined;
    logo: string;
    address: string;
    distance?: number | undefined;
    cuisines?: string[] | undefined;
    agendaBlockId: number;
  }[];
  setRestaurantsForOrders: React.Dispatch<
    React.SetStateAction<
      {
        id: string | number;
        name: string;
        status?: "Open" | "Close" | undefined;
        logo: string;
        address: string;
        distance?: number | undefined;
        cuisines?: string[] | undefined;
        agendaBlockId: number;
        menuLink?: string;
      }[]
    >
  >;
  eventStartTime?: {
    id: number;
    name: string;
  } | null;
  setSubBlocks?: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        name: string;
        startTime: string;
        endTime: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        } | null;
        index: number;
        docsId: number[];
        agendaItemId: string;
      }[]
    >
  >;
  subBlocks?: {
    id: string;
    name: string;
    startTime: string;
    endTime: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    } | null;
    index: number;
    docsId: number[];
    agendaItemId: string;
  }[];
  setIsAgendaUpdated?: () => void;
  currentEventId?: number;
  mealBlockId?: number;
}

export default function ModalAgenda({
  setIsOpen,
  setAgenda,
  agenda,
  currentItem = {
    id: "",
    icon: "",
    name: "",
    description: "",
    duration: {
      id: 0,
      name: "",
      value: 0,
    },
    startTime: "",
    endTime: "",
    type: AgendaBlockType.Session,
    index: 0,
    isEditable: true,
  },
  setIsEdit,
  isEdit,
  location,
  latitude,
  longitude,
  setMealSelectedOrders,
  dayId,
  setIsPickup,
  isPickup,
  creatorIsOrder,
  setCreatorIsOrder,
  selectedRestaurant,
  setSelectedRestaurant,
  setOrders,
  orders,
  selectedAvailableMealsForAttendees,
  setSelectedAvailableMealsForAttendees,
  setMealBlocksWithoutMealsSelection,
  agendaBlockId = 0,
  restaurantsForOrders,
  setRestaurantsForOrders,
  eventStartTime,
  subBlocks,
  setSubBlocks,
  setIsAgendaUpdated,
  currentEventId,
  mealBlockId,
}: Props) {
  const [currentTab, setCurrentTab] = useState<{
    id: number;
    name: string;
    type?: AgendaBlockType;
  } | null>(null);
  const [selectedDuration, setSelectedDuration] = useState<{
    id: number;
    name: string;
    value?: number;
  } | null>(null);
  const [showSelectDuration, setShowSelectDuration] = useState(false);
  const [sessionName, setSessionName] = useState(
    currentItem.name.length
      ? currentItem.name
      : currentTab?.name !== "Session"
      ? currentTab?.name
      : ""
  );
  const [sessionDescription, setSessionDescription] = useState("");
  const [agendaNameError, setAgendaNameError] = useState(false);
  const [agendaDescriptionError, setAgendaDescriptionError] = useState(false);
  const [agendaDurationError, setAgendaDurationError] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [showSelectRestaurantModal, setShowSelectRestaurantModal] =
    useState(false);
  const { startTime } = useContext(EventValue);
  const selectedStartTime = startTime || eventStartTime;
  const [subBlockModalIsOpen, setSubBlockModalIsOpen] = useState(false);
  const [currentBlock, setCurrentBlock] = useState<{
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number;
    isEditable: boolean;
  } | null>(null);
  const [menu, setMenu] = useState<
    {
      name: string;
      subcategory_id: string;
      menu_item_list: {
        count: number;
        name: string;
        price: number;
        qty_available: null;
        unit_size: null;
        unit_of_measurement: string;
        description: string;
        is_available: boolean;
        image: string;
        customizations: [
          {
            name: string;
            min_choice_options: number;
            max_choice_options: number;
            options: [
              {
                name: string;
                price: number;
                customizations: any[];
                min_qty: number;
                max_qty: number;
                conditional_price: {};
                formatted_price: string;
                default_qty: number;
                option_id: string;
              }
            ];
            customization_id: string;
          }
        ];
        min_price: number;
        original_price: number;
        formatted_price: string;
        attributes: [];
        product_id: string;
        thumbnail_image: string;
        should_fetch_customizations: boolean;
        supports_image_scaling: boolean;
      }[];
    }[]
  >([]);
  const token = localStorage.getItem("token");
  const [orderedMeals, setOrderedMeals] = useState<
    {
      customizations: {
        customizationId: string;
        optionId: string;
        markedPrice: number;
      }[];
      productId: string;
      markedPrice: number;
      notes: string;
    }[]
  >([]);
  const [isLoadingMealBlock, setIsLoadingMealBlock] = useState(false);

  if (!selectedStartTime && !currentItem) {
    setIsOpen(false);
  }

  function createNewAgendaItem(mealBlockId?: number) {
    if (selectedStartTime) {
      if (!agendaDurationError && !agendaNameError && !agendaDescriptionError) {
        let newItem: {
          id: string;
          icon: string;
          name: string;
          description: string;
          duration: {
            id: number;
            name: string;
            value?: number | undefined;
          };
          startTime: string;
          endTime: string;
          type: AgendaBlockType;
          index: number;
          isEditable: boolean;
          mealBlockId?: number;
          restaurantName?: string;
        } = currentItem;
        newItem = { ...newItem, index: agendaBlockId };

        if (currentTab?.type === AgendaBlockType.Break) {
          newItem = { ...newItem, icon: clockPlusIcon };
        }

        if (currentTab?.type === AgendaBlockType.Meal) {
          newItem = { ...newItem, icon: happyFaceIcon };
          newItem = { ...newItem, mealBlockId: mealBlockId };
          newItem = { ...newItem, restaurantName: selectedRestaurant?.name };
        }

        if (currentTab?.type === AgendaBlockType.Session) {
          newItem = { ...newItem, icon: presentationIcon };
        }

        newItem = {
          ...newItem,
          name: sessionName?.length ? sessionName : currentItem.name,
        };

        newItem = { ...newItem, description: sessionDescription };

        if (!isEdit) {
          newItem = {
            ...newItem,
            startTime:
              agenda.length === 0
                ? selectedStartTime.name
                : agenda[agenda.length - 1].endTime,
          };
        }

        if (isEdit) {
          newItem = {
            ...newItem,
            startTime: agenda.find((item) => item.id === newItem.id)!.startTime,
          };
        }

        newItem = {
          ...newItem,
          type: currentTab?.type || AgendaBlockType.Session,
        };

        let endHour: number = 0;

        let endMinutes = 0;

        if (selectedDuration) {
          newItem = { ...newItem, duration: selectedDuration };

          if (selectedDuration.value! >= 60) {
            endHour =
              +newItem.startTime.split(":")[0] +
              Math.floor(selectedDuration.value! / 60);

            endMinutes =
              selectedDuration.value! +
              +newItem.startTime.split(":")[1].slice(0, 2) -
              (endHour - +newItem.startTime.split(":")[0]) * 60;

            if (endMinutes >= 60) {
              endHour = endHour + Math.floor(endMinutes / 60);
              endMinutes = endMinutes - Math.floor(endMinutes / 60) * 60;
            }
          } else {
            endHour = +newItem.startTime.split(":")[0];
            endMinutes =
              selectedDuration.value! +
              +newItem.startTime.split(":")[1].slice(0, 2);

            if (endMinutes >= 60) {
              endHour = endHour + Math.floor(endMinutes / 60);
              endMinutes = endMinutes - Math.floor(endMinutes / 60) * 60;
            }
          }
        }

        const lastAMTimeInMinutes = 719;
        const endHourToCalculate = () => {
          if (endHour) {
            if (
              endHour < +newItem.startTime.split(":")[0] &&
              startTime?.name.slice(-2) === "AM"
            ) {
              return endHour + 12;
            }

            if (
              endHour > +newItem.startTime.split(":")[0] &&
              startTime?.name.slice(-2) === "PM"
            ) {
              return endHour + 12;
            }

            if (
              endHour > +newItem.startTime.split(":")[0] &&
              startTime?.name.slice(-2) === "AM"
            ) {
              return endHour;
            }

            if (
              endHour < +newItem.startTime.split(":")[0] &&
              startTime?.name.slice(-2) === "PM"
            ) {
              return endHour;
            }

            return endHour;
          }
          return 0;
        };
        const dayPart = endHour
          ? endHourToCalculate() * 60 + endMinutes > lastAMTimeInMinutes
            ? "PM"
            : "AM"
          : startTime?.name.slice(-2);

        newItem = {
          ...newItem,
          endTime: `${endHour}:${
            endMinutes! > 0 ? endMinutes : "00"
          } ${dayPart}`,
          isEditable: true,
        };

        return newItem;
      }
    }
  }

  const onAddItemWithSubBlocks = (block: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number;
    isEditable: boolean;
  }) => {
    setAgenda((prevState) =>
      [...prevState.filter((item) => item.id !== block.id), block].sort(
        (it1, it2) => it1.index - it2.index
      )
    );
  };

  const onAddItem = () => {
    setIsAdd(true);

    if (!sessionName?.trim().length) {
      setAgendaNameError(true);
    }

    if (sessionName?.trim().length) {
      setAgendaNameError(false);
    }

    if (!selectedDuration) {
      setAgendaDurationError(true);
    }

    if (selectedDuration) {
      setAgendaDurationError(false);
    }
  };

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  useEffect(() => {
    if (!sessionName?.length) {
      setSessionName(currentItem.name || "");
    }
  }, [sessionName]);

  useEffect(() => {
    window.scrollTo(0, 0);

    document.getElementById("modal")?.classList.add("modal-main-container");

    document.body.style.overflow = "hidden";

    if (currentItem.name.length) {
      setIsEdit(true);
      setCurrentTab(tabs.find((tab) => tab.type === currentItem.type) || null);
      setSelectedDuration(currentItem.duration);
      setSessionDescription(currentItem.description);
    }

    return () => {
      document.body.style.overflow = "auto";
      document
        .getElementById("modal")
        ?.classList.remove("modal-main-container");
    };
  }, []);

  useEffect(() => {
    setSessionName(currentTab?.name !== "Session" ? currentTab?.name : "");
    localStorage.setItem("isFirstMapLoad", "true");
  }, [currentTab]);

  useEffect(() => {
    const newItem = createNewAgendaItem(mealBlockId);

    if (isAdd && newItem) {
      if (isEdit) {
        const indexOfEditItem = agenda.findIndex(
          (item) => item.id === newItem.id
        );

        setAgenda((prevState) => [
          ...prevState
            .filter((agendaBlock) => agendaBlock.id !== newItem.id)
            .slice(0, indexOfEditItem),
          newItem,
          ...prevState
            .filter((agendaBlock) => agendaBlock.id !== newItem.id)
            .slice(indexOfEditItem),
        ]);

        setIsEdit(false);
      }

      if (!isEdit) {
        const newAgendaItemId = crypto.randomUUID();

        setAgenda((prevState) => [
          ...prevState,
          { ...newItem, id: newAgendaItemId },
        ]);
        let orderedMeals: {
          customizations?: [
            {
              customizationId: string;
              optionId: string;
              markedPrice: number;
            }
          ];
          productId: string;
          markedPrice: number;
          notes: string;
        }[] = [];

        menu.map((category) => {
          const ordersFromCategory = category.menu_item_list.map(
            (menuItem) => ({
              customizations: undefined,
              productId: menuItem.product_id,
              markedPrice: menuItem.price,
              notes: "",
            })
          );

          orderedMeals = [...orderedMeals, ...ordersFromCategory];
        });

        if (
          currentTab?.type === AgendaBlockType.Meal &&
          orderedMeals.length === 0
        ) {
          setMealBlocksWithoutMealsSelection((prevState) => [
            ...prevState,
            newAgendaItemId,
          ]);
        }

        if (selectedRestaurant) {
          const order = {
            restaurantId: selectedRestaurant?.id,
            mealAgendaItemId: newAgendaItemId,
            orderedMeals: orderedMeals,
          };

          setMealSelectedOrders((prevState) => [...prevState, order]);
          setSelectedRestaurant(null);
          setMenu([]);
        }
      }

      setIsAgendaUpdated && setIsAgendaUpdated();
      setIsOpen(false);
      setIsAdd(false);
    }
  }, [agendaNameError, agendaDescriptionError, agendaDurationError, isAdd]);

  const RenderSelectMealsButton = () => {
    if (isLoadingMealBlock) {
      return <Loader size='md' scale="0.3" />
    }

    if (!selectedRestaurant) {
      return <Button
      buttonType="regular"
      size="md"
      hierarchy="linkColor"
      onClick={() => setShowSelectRestaurantModal(true)}
      fullWidth
      paddingNone
      disabled={!!(!selectedDuration || !sessionName)}
    >
      <div
        className={`openButton ${
          selectedDuration ? "" : "openButton-width-content"
        } text-sm`}
      >
        <img
          src={plusCircle}
          alt="chevron down icon"
          className="calendarIcon"
        />
        <p>Add restaurant</p>
      </div>
    </Button>
    }

    if (selectedRestaurant) {
      return <div className="flex flex-column gap-4">
                      <p className="text-sm font-medium">Restaurant</p>
                      <div
                        className={`dropdown dropdown-withBorder`}
                        onClick={() => setShowSelectRestaurantModal(true)}
                      >
                        <h1 className="text-md font-regular">
                          {selectedRestaurant ? selectedRestaurant.name : null}
                        </h1>
                        <img src={pencilIcon} alt="arrow down" />
                      </div>
                    </div>
    }

    return null
  }

  return (
    <div className="modalNotification h">
      {createPortal(
        <SelectMeal
          setOrderedMeals={setOrderedMeals}
          orderedMeals={orderedMeals}
          showSelectRestaurantModal={showSelectRestaurantModal}
          agendaBlockStartTime={getTimeInMinutes(
            agenda.length === 0
              ? selectedStartTime!.name
              : agenda[agenda.length - 1].endTime
          )}
          dayId={dayId}
          setCreatorIsOrder={setCreatorIsOrder}
          creatorIsOrder={creatorIsOrder}
          onAddBlock={onAddItem}
          location={location}
          latitude={latitude}
          longitude={longitude}
          setSelectedRestaurant={setSelectedRestaurant}
          selectedRestaurant={selectedRestaurant}
          setShowSelectRestaurantModal={setShowSelectRestaurantModal}
          setIsPickup={setIsPickup}
          isPickup={isPickup}
          setOrders={setOrders}
          orders={
            orders.filter((item) => item.agendaBlockId === agendaBlockId) || []
          }
          selectedAvailableMealsForAttendees={
            selectedAvailableMealsForAttendees
          }
          setSelectedAvailableMealsForAttendees={
            setSelectedAvailableMealsForAttendees
          }
          agendaBlockId={agendaBlockId}
          restaurantsForOrders={restaurantsForOrders}
          setRestaurantsForOrders={setRestaurantsForOrders}
          mealBLockId={mealBlockId}
          setIsLoadingMealBlock={setIsLoadingMealBlock}
        />,
        document.getElementById("modal") as HTMLElement
      )}
      {subBlocks &&
        setSubBlocks &&
        createPortal(
          <AddSubBlockModal
            showAddSubBlockModal={subBlockModalIsOpen}
            setShowAddSubBlockModal={setSubBlockModalIsOpen}
            block={currentBlock}
            onAddBlock={() => {
              if (currentBlock) {
                onAddItemWithSubBlocks(currentBlock);
                setIsOpen(false);
              }
            }}
            subBlocks={subBlocks}
            setSubBlocks={setSubBlocks}
            setIsAgendaUpdated={setIsAgendaUpdated}
            agendaStartTime={selectedStartTime?.name || ""}
            agenda={
              currentBlock &&
              !agenda.some((block) => block.id === currentBlock.id)
                ? [...agenda, currentBlock]
                : agenda
            }
            eventId={currentEventId || 0}
          />,
          document.getElementById("modal") as HTMLElement
        )}
      <div className="modalNotification-container modalNotification-container-agenda">
        <div className="modalNotification-circles-container">
          <div className="modalNotification-circles">
            <div className="eventDetails_circles">
              <div className="eventDetails-circle eventDetails-circle-fifth">
                <div className="eventDetails-circle eventDetails-circle-fourth">
                  <div className="eventDetails-circle eventDetails-circle-third">
                    <div className="eventDetails-circle eventDetails-circle-second">
                      <div className="eventDetails-circle eventDetails-circle-first"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modalNotification-header">
          <div className="modalNotification-header-actions">
            <FeaturedIcon icon={announcementIcon} size="lg" type="gray" />
            <img
              src={closeIcon}
              alt="close icon"
              className="modalNotification-header-actions-close"
              onClick={() => {
                setIsOpen(false);
                setIsEdit(false);
              }}
            />
          </div>
          <div className="modalNotification-header-info">
            <h3>Add agenda block</h3>
          </div>
        </div>
        <div className="modalNotification-main modalNotification-main-agenda">
          {/* <Tabs 
            tabs={tabs}
            current={currentTab}
            setCurrent={setCurrentTab}
          /> */}
          <Dropdown
            design="new"
            reactToFocus
            height="auto"
            content={tabs}
            currentItem={currentTab}
            setCurrentItem={setCurrentTab}
            withBorder
            id="agendaCreate"
            placeholder="Agenda type"
            label="Agenda type"
          />
          {currentTab ? (
            <>
              <FormInput
                label={sessionName?.length ? "Name" : "Session name"}
                type="Text"
                onChange={(
                  event: React.ChangeEvent<
                    HTMLInputElement | HTMLTextAreaElement
                  >
                ) => setSessionName(event.target.value)}
                value={sessionName}
                isError={agendaNameError}
                hasHelpIcon={agendaNameError}
                design="new"
                errorMessage="Please, enter the name."
              />
              {currentTab?.name === "Session" ? (
                <FormInput
                  label="Description"
                  type="TextArea"
                  value={sessionDescription}
                  onChange={(
                    event: React.ChangeEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >
                  ) => setSessionDescription(event.target.value)}
                  isError={agendaDescriptionError}
                  design="new"
                />
              ) : null}
              <DurationsDropdown
                isError={agendaDurationError}
                selectedDuration={selectedDuration}
                setSelectedDuration={setSelectedDuration}
              />
              {currentTab.type === AgendaBlockType.Meal ? <RenderSelectMealsButton /> : null}
              {currentTab.type === AgendaBlockType.Session &&
              !!(selectedDuration && sessionName) ? (
                <Button
                  buttonType="regular"
                  size="md"
                  hierarchy="linkColor"
                  onClick={() => {
                    if (isEdit) {
                      setCurrentBlock({
                        ...currentItem,
                        duration: selectedDuration,
                        name: sessionName,
                      });
                      setSubBlockModalIsOpen(true);
                    } else {
                      const newItem = createNewAgendaItem() || null;
                      const newAgendaItemId = crypto.randomUUID();
                      if (newItem) {
                        setCurrentBlock({
                          ...newItem,
                          duration: selectedDuration,
                          id: newAgendaItemId,
                        });
                        setSubBlockModalIsOpen(true);
                      }
                    }
                  }}
                  fullWidth
                  paddingNone
                  disabled={!!(!selectedDuration || !sessionName)}
                >
                  <div
                    className={`openButton ${
                      selectedDuration ? "" : "openButton-width-content"
                    } text-sm`}
                  >
                    <p>Add Details</p>
                    <img
                      src={plusCircle}
                      alt="chevron down icon"
                      className="calendarIcon"
                    />
                  </div>
                </Button>
              ) : null}
            </>
          ) : null}
        </div>
        <div
          className="modalNotification-actions modalNotification-actions-agenda"
          style={{ justifyContent: "end" }}
        >
          <Button
            buttonType="regular"
            hierarchy="newDesign-primary"
            size="lg"
            contentCenter
            newDesignPaddingNone
            onClick={() => {
              onAddItem();
            }}
          >
            {isEdit ? "Save" : "Add block"}
          </Button>
        </div>
      </div>
    </div>
  );
}
