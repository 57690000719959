import { createContext, useState } from "react";
import { AgendaBlockType } from "../types/agendaBlockType";
import { AttendeeRSVP } from "../types/attendeeRSVP";
import { Moment } from "moment";

export const EventValue = createContext<{
  eventIds: { eventId: number; eventDayId: number } | null;
  startTime: { id: number; name: string } | null;
  endTime: { id: number; name: string } | null;
  eventName: string | null;
  attachedDocumentsCount: number;
  eventDescription: string | null;
  eventDate: moment.Moment | null;
  timeZone: { id: number; name: string; timezone?: string } | null;
  location: {
    id: number;
    name: string;
    supportText?: string | undefined;
    lng?: string;
    lat?: string;
  } | null;
  locationNotes?: string;
  agenda: {
    id: string;
    icon: string;
    name: string;
    description: string;
    duration: {
      id: number;
      name: string;
      value?: number | undefined;
    };
    startTime: string;
    endTime: string;
    type: AgendaBlockType;
    index: number;
    isEditable: boolean;
    mealBlockId?: number;
    restaurantName?: string;
  }[];
  invitedAttendees?: {
    attendees: {
      id: number;
      networkMemberId: number;
      status: "added";
      rsvp: AttendeeRSVP | null;
      networkMember: {
        id: number;
        fullName: string;
        email: string;
        phone: string;
        businessName: string;
        address: string;
        title: string;
        userId: null | number;
        accountId: number;
        createdAt: string;
        updatedAt: string;
        user: {
          accountId: number;
          createdAt: string;
          email: string;
          fullName: string;
          id: number;
          phone?: string | null;
          status: string;
          supabaseId: string;
          updatedAt: string;
        } | null;
      };
    }[];
    count: number;
  };
  currentLastStep: number;
  slug: string;
  setSelectedStartTime: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
    } | null>
  >;
  setSelectedEndTime: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
    } | null>
  >;
  setEventDate: React.Dispatch<React.SetStateAction<Moment | null>>;
  setEventName: React.Dispatch<React.SetStateAction<string>>;
  setEventIds: React.Dispatch<
    React.SetStateAction<{
      eventId: number;
      eventDayId: number;
    } | null>
  >;
  setEventDescription: React.Dispatch<React.SetStateAction<string>>;
  setSelectedLocation: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      supportText?: string | undefined;
      lng?: string | undefined;
      lat?: string | undefined;
    } | null>
  >;
  setLocationNotes: React.Dispatch<React.SetStateAction<string>>;
  setSelectedZone: React.Dispatch<
    React.SetStateAction<{
      id: number;
      name: string;
      timezone?: string | undefined;
    } | null>
  >;
  setAgenda: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        icon: string;
        name: string;
        description: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        };
        startTime: string;
        endTime: string;
        type: AgendaBlockType;
        mealBlockId?: number | undefined;
        index: number;
        isEditable: boolean;
        restaurantName?: string;
      }[]
    >
  >;
  setInvitedAttendees: React.Dispatch<
    React.SetStateAction<{
      attendees: {
        id: number;
        networkMemberId: number;
        status: "added";
        rsvp: AttendeeRSVP | null;
        networkMember: {
          id: number;
          fullName: string;
          email: string;
          phone: string;
          businessName: string;
          address: string;
          title: string;
          userId: null | number;
          accountId: number;
          createdAt: string;
          updatedAt: string;
          user: {
            accountId: number;
            createdAt: string;
            email: string;
            fullName: string;
            id: number;
            phone?: string | null;
            status: string;
            supabaseId: string;
            updatedAt: string;
          } | null;
        };
      }[];
      count: number;
    }>
  >;
  setAttachedFilesCount: React.Dispatch<React.SetStateAction<number>>;
  setCurrentLastStep: React.Dispatch<React.SetStateAction<number>>;
  setSlug: React.Dispatch<React.SetStateAction<string>>;
}>({
  eventIds: null,
  agenda: [],
  endTime: null,
  eventDate: null,
  eventDescription: null,
  eventName: null,
  invitedAttendees: {
    attendees: [],
    count: 0,
  },
  location: null,
  locationNotes: "",
  startTime: null,
  timeZone: null,
  attachedDocumentsCount: 0,
  currentLastStep: 1,
  slug: "",
  setSelectedStartTime: () => {},
  setSelectedEndTime: () => {},
  setEventDate: () => {},
  setEventName: () => {},
  setEventIds: () => {},
  setEventDescription: () => {},
  setSelectedLocation: () => {},
  setLocationNotes: () => {},
  setSelectedZone: () => {},
  setAgenda: () => {},
  setInvitedAttendees: () => {},
  setAttachedFilesCount: () => {},
  setCurrentLastStep: () => {},
  setSlug: () => {},
});

export function EventInfoProvider({ children }: React.PropsWithChildren) {
  const [selectedStartTime, setSelectedStartTime] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [selectedEndTime, setSelectedEndTime] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [eventDate, setEventDate] = useState<moment.Moment | null>(null);
  const [eventName, setEventName] = useState("");
  const [eventIds, setEventIds] = useState<{
    eventId: number;
    eventDayId: number;
  } | null>(null);
  const [eventDescription, setEventDescription] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<{
    id: number;
    name: string;
    supportText?: string | undefined;
    lng?: string;
    lat?: string;
  } | null>(null);
  const [locationNotes, setLocationNotes] = useState("");
  const [selectedZone, setSelectedZone] = useState<{
    id: number;
    name: string;
    timezone?: string;
  } | null>(null);
  const [agenda, setAgenda] = useState<
    {
      id: string;
      icon: string;
      name: string;
      description: string;
      duration: {
        id: number;
        name: string;
        value?: number | undefined;
      };
      startTime: string;
      endTime: string;
      type: AgendaBlockType;
      mealBlockId?: number;
      index: number;
      isEditable: boolean;
    }[]
  >([]);
  const [invitedAttendees, setInvitedAttendees] = useState<{
    attendees: {
      id: number;
      networkMemberId: number;
      status: "added";
      rsvp: AttendeeRSVP | null;
      networkMember: {
        id: number;
        fullName: string;
        email: string;
        phone: string;
        businessName: string;
        address: string;
        title: string;
        userId: null | number;
        accountId: number;
        createdAt: string;
        updatedAt: string;
        user: {
          accountId: number;
          createdAt: string;
          email: string;
          fullName: string;
          id: number;
          phone?: string | null;
          status: string;
          supabaseId: string;
          updatedAt: string;
        } | null;
      };
    }[];
    count: number;
  }>({ attendees: [], count: 0 });
  const [attachedFilesCount, setAttachedFilesCount] = useState(0);
  const [currentLastStep, setCurrentLastStep] = useState(1);
  const [slug, setSlug] = useState("");

  return (
    <EventValue.Provider
      value={{
        eventIds,
        agenda: agenda,
        endTime: selectedEndTime ? selectedEndTime : null,
        eventDate: eventDate,
        eventDescription: eventDescription,
        eventName: eventName,
        invitedAttendees: invitedAttendees,
        location: selectedLocation,
        locationNotes: locationNotes,
        startTime: selectedStartTime ? selectedStartTime : null,
        timeZone: selectedZone,
        attachedDocumentsCount: attachedFilesCount,
        currentLastStep,
        slug,
        setAgenda,
        setAttachedFilesCount,
        setCurrentLastStep,
        setEventDate,
        setEventDescription,
        setEventIds,
        setEventName,
        setInvitedAttendees,
        setLocationNotes,
        setSelectedEndTime,
        setSelectedLocation,
        setSelectedStartTime,
        setSelectedZone,
        setSlug,
      }}
    >
      {children}
    </EventValue.Provider>
  );
}
