import React, { useState } from "react";
import helpIcon from "../../assets/images/help-circle.svg";
import errorIcon from "../../assets/images/alert-circle.svg";
import eyeOff from "../../assets/images/eye-off.svg";
import eye from "../../assets/images/eye.svg";

interface Props {
  isError?: boolean;
  hasBeforeIcon?: string | boolean;
  disabled?: boolean;
  hasHelpIcon?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  fullWidth?: boolean;
  setShow?: () => void;
  onFocus?: () => void;
  design?: "new" | "old";
  placeholder?: string;
}

export default function PasswordInput({
  disabled,
  fullWidth,
  hasHelpIcon,
  isError,
  onChange,
  value,
  onFocus,
  setShow,
  design,
  placeholder,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div
      autoFocus
      onBlur={setShow}
      onFocus={(e) => {
        e.preventDefault();
        onFocus && onFocus();
      }}
      className={`${design === "new" ? "inputNew inputNew-filled" : "input"} ${
        isError && design === "old"
          ? "input-error"
          : disabled
          ? "input-disabled"
          : ""
      } ${fullWidth ? "fullWidth" : ""}`}
      tabIndex={0}
    >
      <div
        className={`inputWrapper ${value?.length ? "inputWrapper-filled" : ""}`}
      >
        <input
          type={showPassword ? "text" : "password"}
          className="text-md"
          onChange={onChange}
          value={value}
          tabIndex={1}
          placeholder={placeholder}
        />
      </div>
      <img
        src={showPassword ? eye : eyeOff}
        alt=""
        width={20}
        height={20}
        className='showPasswordIcon'
        onClick={() => setShowPassword((prevState) => !prevState)}
      />
      {hasHelpIcon && design === "old" ? (
        <img src={isError ? errorIcon : helpIcon} alt="help" />
      ) : null}
    </div>
  );
}
