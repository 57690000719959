import * as React from "react";
import mapboxgl, { MapMouseEvent } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl";
import { useEffect, useRef, useState } from "react";
import marker from "../../assets/images/mapMarker.png";
import Loader from "../Loader";
import AddNewRestaurantBar from "./AddNewRestaurantBar";

const Marker = (onClick: () => void) => {
  const img = document.createElement("img");
  img.className = "cursorPointer";
  img.src = marker;
  img.onclick = onClick;

  return img;
};

interface Props {
  restaurants?: {
    stores: {
      _id: string;
      name: string;
      phone_number: number;
      address: {
        street_addr: string;
        city: string;
        state: string;
        zipcode: string;
        country: string;
        street_addr_2: string;
        latitude: number;
        longitude: number;
      };
      description: string;
      local_hours: {
        operational: {
          Monday: string;
          Tuesday: string;
          Wednesday: string;
          Thursday: string;
          Friday: string;
          Saturday: string;
          Sunday: string;
        };
        delivery: {
          Monday: string;
          Tuesday: string;
          Wednesday: string;
          Thursday: string;
          Friday: string;
          Saturday: string;
          Sunday: string;
        };
        pickup: {
          Monday: string;
          Tuesday: string;
          Wednesday: string;
          Thursday: string;
          Friday: string;
          Saturday: string;
          Sunday: string;
        };
        dine_in: {
          Monday: string;
          Tuesday: string;
          Wednesday: string;
          Thursday: string;
          Friday: string;
          Saturday: string;
          Sunday: string;
        };
      };
      cuisines: string[];
      food_photos: string[];
      logo_photos: string[];
      store_photos: string[];
      dollar_signs: number;
      pickup_enabled: boolean;
      delivery_enabled: boolean;
      is_open: boolean;
      offers_first_party_delivery: boolean;
      offers_third_party_delivery: boolean;
      quote_ids: string[];
      miles: number;
      weighted_rating_value: number;
      aggregated_rating_count: number;
      supports_upc_codes: boolean;
      type: "restaurant";
    }[];
    next_page: number;
  };
  setSelectedRestaurant?: React.Dispatch<
    React.SetStateAction<{
      id: string | number;
      name: string;
      logo: string;
      status?: "Open" | "Close";
      address: string;
      distance?: number;
      cuisines?: string[];
    } | null>
  >;
  setRestaurantsForOrders?: React.Dispatch<
    React.SetStateAction<
      {
        id: string | number;
        name: string;
        status?: "Open" | "Close" | undefined;
        logo: string;
        address: string;
        distance?: number | undefined;
        cuisines?: string[] | undefined;
        agendaBlockId: number;
      }[]
    >
  >;
  agendaBlockId: number;
  location: string;
  lng: number;
  lat: number;
  withoutControls?: boolean;
  zoom?: number;
  fullHeight?: boolean;
  onRestaurantSelect?: () => void;
  setAddCustomRestaurantModalIsOpen?: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export function MapboxMap({
  restaurants,
  setSelectedRestaurant,
  setRestaurantsForOrders,
  agendaBlockId,
  location,
  lng,
  lat,
  withoutControls,
  zoom = 12.3,
  fullHeight,
  onRestaurantSelect,
  setAddCustomRestaurantModalIsOpen,
}: Props) {
  const [map, setMap] = useState<mapboxgl.Map>();
  const [showLoader, setShowLoader] = useState<boolean>(true);
  localStorage.setItem("isFirstMapLoad", "true");
  const isFirstIdle = localStorage.getItem("isFirstMapLoad");
  const mapNode = useRef(null);
  const mapControl = new mapboxgl.NavigationControl();

  useEffect(() => {
    const node = mapNode.current;
    if (typeof window === "undefined" || node === null) return;

    const mapboxMap = new mapboxgl.Map({
      container: node,
      accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
      style: "mapbox://styles/mapbox/outdoors-v12",
      center: [lng, lat],
      zoom,
      trackResize: true,
      minZoom: zoom,
      maxZoom: zoom,
    });

    setMap(mapboxMap);

    return () => {
      mapboxMap.remove();
    };
  }, []);

  if (map && !withoutControls) {
    map.on("load", () => {
      map.addControl(
        new mapboxgl.FullscreenControl({
          container: document.querySelector("body") || undefined,
        })
      );
      map.addControl(mapControl, "bottom-right");
    });
  }

  if (isFirstIdle) {
    map?.on("idle", () => {
      if (isFirstIdle) {
        localStorage.removeItem("isFirstMapLoad");
        // map.setZoom(12.3);
        map.resize();
        map.setMaxZoom(25);
        setTimeout(() => setShowLoader(false), 1000);
      }
    });
  }

  useEffect(() => {
    if (map && restaurants?.stores.length) {
      restaurants?.stores.map((restaurant) =>
        new mapboxgl.Marker({
          draggable: false,
          element: Marker(() => {
            setSelectedRestaurant &&
              setSelectedRestaurant({
                id: restaurant._id,
                name: restaurant.name,
                logo: restaurant.logo_photos[0],
                status: restaurant.is_open ? "Open" : "Close",
                address: restaurant.address.street_addr,
                distance: restaurant.miles,
                cuisines: restaurant.cuisines,
              });
            onRestaurantSelect && onRestaurantSelect();
            setRestaurantsForOrders &&
              setRestaurantsForOrders((prevState) => {
                return [
                  ...prevState.filter(
                    (item) => item.agendaBlockId !== agendaBlockId
                  ),
                  {
                    id: restaurant._id,
                    name: restaurant.name,
                    status: restaurant.is_open ? "Open" : "Close",
                    logo: restaurant.logo_photos[0],
                    address: location,
                    cuisines: restaurant.cuisines,
                    distance: restaurant.miles,
                    agendaBlockId: agendaBlockId,
                  },
                ];
              });
          }),
        })
          .setLngLat({
            lng: restaurant.address.longitude,
            lat: restaurant.address.latitude,
          })
          .addTo(map)
      );
    }
  }, [map, restaurants]);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      {showLoader ? (
        <div
          style={{
            position: "absolute",
            left: "0",
            right: "0",
            top: "0",
            bottom: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "1000000",
            background: "#fff",
          }}
        >
          <Loader size={"lg"} />
        </div>
      ) : null}
      <div
        ref={mapNode}
        style={{ width: "100%", height: fullHeight ? "100%" : "85%" }}
      />
      {setAddCustomRestaurantModalIsOpen ? (
        <AddNewRestaurantBar
          setAddCustomRestaurantModalIsOpen={setAddCustomRestaurantModalIsOpen}
        />
      ) : null}
    </div>
  );
}
