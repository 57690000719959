import React from "react";
import cursorIcon from "../../assets/images/cursor-click-01.svg";

interface Props {
  setAddCustomRestaurantModalIsOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

export default function AddNewRestaurantBar({
  setAddCustomRestaurantModalIsOpen,
}: Props) {
  return (
    <div
      className="addNewRestaurantBar"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setAddCustomRestaurantModalIsOpen(true);
      }}
    >
      <img src={cursorIcon} alt="" />
      <p>Not seeing a restaurant? Add it yourself!</p>
    </div>
  );
}
