import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { Button } from "../../components/Buttons/Button";
import ReviewAgendaSection from "../../components/Sections/ReviewAgendaSection";
import LogoRepeat from "../../components/LogoRepeat/LogoRepeat";
import pathIcon from "../../assets/images/path90.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import usersPlusIcon from "../../assets/images/users-plus.svg";
import {
  editAgendaSubBlocks,
  editEventAgenda,
  fetchAttendeesNetwork,
  fetchDocument,
  fetchEvent,
  fetchInvitedAttendees,
  fetchMeals,
  fetchTodoItems,
  finalizeMealBlock,
  postAgendaSubBlocks,
  postAttendeeRSVP,
  postMeals,
  postMealsSelections,
  postMealsSelectionsBulk,
  postPreSelect,
  postRestaurants,
  queryClient,
} from "../../httpQueries/http";
import presentationIcon from "../../assets/images/presentation-chart-01.svg";
import clockPlusIcon from "../../assets/images/clock-plus.svg";
import happyFaceIcon from "../../assets/images/face-happy.svg";
import {
  durations,
  smallDurations,
} from "../../utilities/agendaDurationsArray";
import { editTime } from "../../utilities/EditAgendaBlockTime";
import { logoColor } from "../../types/logoColor";
import { logoImage } from "../../types/logoImage";
import { getLogoRepeatImage } from "../../utilities/getLogoRepeatImage";
import { AttendeeRSVP } from "../../types/attendeeRSVP";
import Loader from "../../components/Loader";
import { AgendaBlockType } from "../../types/agendaBlockType";
import { createPortal } from "react-dom";
import { MealBlockChoice, MealBlockType } from "../../types/meals";
import PreviewPreselectedMealsInviteesMeals from "./SelectCustomizationAttendeesView/PreviewPreselectedMealsForAttendees";
import { TodoItem, TodoItemType } from "../../types/todoItem";
import { markTodoAsCompleted } from "../../utilities/markTodoAsCompleted";
import checkCircleIcon from "../../assets/images/check-circle-black.svg";
import alertTriangle from "../../assets/images/alert-triangle.svg";
import userXIcon from "../../assets/images/user-x-01.svg";
import checkSuccessIcon from "../../assets/images/check-circle-green.svg";
import { NotificationValue } from "../../utilities/NotificationContext";
import { getEventTime } from "../../utilities/getEventStartTime";
import { RSVPEventValue } from "../../utilities/RSVPEventContext";
import pdfIcon from "../../assets/images/fileTypeIconPdf.svg";
import EventInfoPreview from "../../components/Previews/EventInfoPreview";
import fileAttachmentIcon from "../../assets/images/file-attachment-05-black.svg";
import { DropdownMenu } from "../../components/Dropdown/DropdownMenu";
import linkIcon from "../../assets/images/link-03.svg";
import uploadCloudIcon from "../../assets/images/upload-cloud-02-black.svg";
import Document from "../../components/Document/Document";
import fileTypeIcon from "../../assets/images/file_type_icon_link.svg";
import ModalAgenda from "../../components/Modal/ModalAgenda";
import { editSubBlockTime } from "../../utilities/editSubBlockTime";
import ReviewEventDetailsHeader from "./Components/Header";
import RenderModals from "./Components/Modals";
import MainHero from "./Components/MainHero";
import EventActions from "./Components/EventActions";
import AttendeeActions from "./Components/AttendeeActions";
import { GetTodoItems } from "./handlers/getTodoItems";
import { getAgendaItemOnClickProps } from "./handlers/getAgendaItemOnClickProps";
import ReviewSelectionsInviteeView from "./Components/ReviewSelectionsInviteeView";
import UsersThatMadeOrderCountContainer from "../../components/UsersThatMadeOrderCount/UsersThatMadeOrderCountContainer";
import UsersThatMadeOrderCountWidget from "../../components/UsersThatMadeOrderCount/UsersThatMadeOrderCountWidget";
import { openSelectMealsModal } from "./handlers/openSelectMealsModal";
import { MenuContext, MenuValue } from "../../utilities/menuContext";

export default function ReviewEventDetailsView() {
  const slug = useParams().slug;
  const [selectedRSVP, setSelectedRSVP] = useState<AttendeeRSVP | undefined>();
  const [isAttachDocumentModalOpen, setIsAttachDocumentModalOpen] =
    useState(false);
  const [isDocumentsUploaded, setIsDocumentsUploaded] = useState(false);
  const {
    data: event,
    isFetching,
    refetch: refetchEvent,
  } = useQuery({
    queryKey: ["event", slug, isDocumentsUploaded],
    queryFn: () => fetchEvent({ slug, token }),
  });
  const { data: todoItems, refetch } = useQuery({
    queryKey: ["todos", event || {}, event?.rsvp || AttendeeRSVP.Maybe],
    queryFn: async () => {
      let todos: { todoItems: TodoItem[]; total: number } = {
        todoItems: [],
        total: 0,
      };

      const fetchedTodos = await fetchTodoItems({
        token,
        take: 10,
        skip: 0,
        eventId: event?.id,
      });

      if (fetchedTodos?.todoItems.length) {
        todos = fetchedTodos;
      }

      return todos;
    },
  });
  const [preOrder, setPreOrder] = useState<
    {
      customizations: {
        customizationId: string;
        optionId: string[];
        markedPrice: number;
      }[];
      productId: string;
      markedPrice: number;
      notes: string;
      count: number;
    }[]
  >([]);
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [selectedAddAttendees, setSelectedAddAttendees] = useState<{
    id: number;
    name: string;
    supportText?: string | undefined;
    value?: number | undefined;
  } | null>(null);
  const [userDeleted, setUserDeleted] = useState<number | null>(null);
  const [createNewUserIsOpen, setCreateNewUserIsOpen] = useState(false);
  const [attendees, setAttendees] = useState<
    | {
        attendees: {
          id: number;
          networkMemberId: number;
          status: "added";
          rsvp: AttendeeRSVP | null;
          networkMember: {
            id: number;
            fullName: string;
            email: string;
            phone: string;
            businessName: string;
            address: string;
            title: string;
            userId: null | number;
            accountId: number;
            createdAt: string;
            updatedAt: string;
            user: {
              accountId: number;
              createdAt: string;
              email: string;
              fullName: string;
              id: number;
              phone?: string | null;
              status: string;
              supabaseId: string;
              updatedAt: string;
            } | null;
          };
          mealSelections: any[];
        }[];
        count: number;
      }
    | "isFetching"
  >({ attendees: [], count: 0 });
  const [searchValue, setSearchValue] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const [attendeesNetwork, setAttendeesNetwork] = useState<
    {
      accountId: number;
      createdAt: string;
      email: string;
      fullName: string;
      id: number;
      phone?: string;
      updatedAt: string;
      address: string | null;
      businessName: string | null;
      title: string | null;
    }[]
  >();
  const [selectMealsModalIsOpen, setSelectMealsModalIsOpen] = useState(false);
  const token = localStorage.getItem("token");
  const [isUsersInvitedQueryWorks, setIsUsersInvitedQueryWorks] =
    useState(true);
  const [mealBlockId, setMealBlockId] = useState<number | undefined>();
  const [selectedRestaurant, setSelectedRestaurant] = useState<{
    id: string | number;
    name: string;
    logo: string;
    status?: "Open" | "Close" | undefined;
    address: string;
    distance?: number | undefined;
    cuisines?: string[] | undefined;
    description: string;
  } | null>(null);
  const [orderMealsModalIsOpen, setOrderMealsModalIsOpen] = useState(false);
  const [mealBlockIdForOrder, setMealBlockIdForOrder] = useState(0);
  const [cartId, setCartId] = useState<null | string>(null);
  const [isEventHaveAlreadyPassed, setIsEventHaveAlreadyPassed] =
    useState(false);
  const [isOrganizer, setIsOrganizer] = useState(false);
  const [pageView, setPageView] = useState<"attendee" | "organizer">(
    "organizer"
  );
  const [isSelectFileModalOpen, setIsSelectFileModalOpen] =
    useState<boolean>(false);
  const [notificationModalIsOpen, setNotificationModalIsOpen] = useState<{
    name: string;
    description: string;
    firstButtonName: string;
    secondButtonName: string;
    onFirstButtonClick: () => void;
    onSecondButtonClick: () => void;
  } | null>(null);
  const [selectedFileModal, setSelectedFileModal] = useState<{
    id: number;
    name: string;
    img?: string;
  } | null>(null);
  const [orders, setOrders] = useState<
    {
      agendaBlockId: number;
      customizations: {
        customizationId: string;
        optionId: string[];
        markedPrice: number;
      }[];
      productId: string;
      markedPrice: number;
      notes: string;
      count: number;
    }[]
  >([]);
  const { setNotification } = useContext(NotificationValue);
  const [eventLogo, setEventLogo] = useState<logoImage>(logoImage.PATH);
  const [eventLogoColor, setEventLogoColor] = useState<logoColor>(
    logoColor.BLUE
  );
  const [isEditLogoModalOpen, setIsEditLogoModalOpen] = useState(false);
  const { eventSlug, setEventSlug } = useContext(RSVPEventValue);
  const [uploadedDocuments, setUploadedDocuments] = useState<
    {
      document: {
        id: number;
        isUploaded: boolean;
        key: string;
        originalFileName: string;
        url: string | null;
      };
      url?: string | undefined;
    }[]
  >([]);
  const [documents, setDocuments] = useState<
    { name: string; url: string; id?: number }[]
  >([]);
  const [links, setLinks] = useState<{ url: string; id: number }[]>([]);
  const [currentEditableLink, setCurrentEditableLink] = useState<
    { url: string; id: number } | undefined
  >();
  const [reviewAttachmentsIsOpen, setReviewAttachmentsIsOpen] = useState(false);
  const [notLogginedUserIsOpen, setNotLogginedUserIsOpen] = useState(false);
  const [showAgendaModal, setShowAgendaModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<
    | {
        id: string;
        icon: string;
        name: string;
        description: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        };
        startTime: string;
        endTime: string;
        type: AgendaBlockType;
        index: number;
        isEditable: boolean;
      }
    | undefined
  >();
  const [agenda, setAgenda] = useState<
    {
      id: string;
      icon: string;
      name: string;
      description: string;
      duration: {
        id: number;
        name: string;
        value?: number | undefined;
      };
      startTime: string;
      endTime: string;
      type: AgendaBlockType;
      mealBlockId?: number;
      index: number;
      isEditable: boolean;
      finalizedUsersId?: number;
    }[]
  >([]);
  const [newOrders, setNewOrders] = useState<
    {
      agendaBlockId: number;
      customizations: {
        customizationId: string;
        optionId: string[];
        markedPrice: number;
      }[];
      productId: string;
      markedPrice: number;
      notes: string;
      count: number;
    }[]
  >([]);
  const [
    selectedAvailableMealsForAttendees,
    setSelectedAvailableMealsForAttendees,
  ] = useState<{ id: number; products: { id: string; name: string }[] }[]>([]);
  const [isPickup, setIsPickup] = useState<{ id: number; state: boolean }[]>(
    []
  );
  const [creatorIsOrder, setCreatorIsOrder] = useState<
    { id: number; state: boolean | null }[]
  >([]);
  const [newSelectedRestaurant, setNewSelectedRestaurant] = useState<{
    id: string | number;
    name: string;
    status?: "Open" | "Close";
    logo: string;
    address: string;
    distance?: number;
    cuisines?: string[];
    menuLink?: string;
  } | null>(null);
  const [restaurantsForOrders, setRestaurantsForOrders] = useState<
    {
      id: string | number;
      name: string;
      status?: "Open" | "Close";
      logo: string;
      address: string;
      distance?: number;
      cuisines?: string[];
      agendaBlockId: number;
      menuLink?: string;
    }[]
  >([]);
  const [subBlocks, setSubBlocks] = useState<
    {
      id: string;
      name: string;
      startTime: string;
      endTime: string;
      duration: {
        id: number;
        name: string;
        value?: number | undefined;
      } | null;
      index: number;
      docsId: number[];
      agendaItemId: string;
    }[]
  >([]);
  const [currentAgendaBlockId, setCurrentAgendaBlockId] = useState<
    number | undefined
  >(undefined);
  const attachments = useMemo(
    () =>
      uploadedDocuments
        ?.map((item) => (
          <Document
            key={item.document.key}
            url={
              item.url ? item.url : item.document.url ? item.document.url : ""
            }
            img={item.document.url ? fileTypeIcon : pdfIcon}
            name={item.document.originalFileName}
            type={item.document.url ? "link" : "document"}
            id={item.document.id}
            eventId={event?.id}
            onEdit={() => {
              setIsAttachDocumentModalOpen(true);
              setSelectedFileModal({
                id: 1,
                name: "Add links",
                img: linkIcon,
              });
              setCurrentEditableLink({
                url: item.document.url || item.url || "",
                id: item.document.id,
              });
              setUploadedDocuments((prevState) =>
                prevState.filter((it) => it.document.id !== item.document.id)
              );
            }}
            onDelete={() => {
              setUploadedDocuments((prevState) =>
                prevState.filter((it) => it.document.id !== item.document.id)
              );
            }}
            onClick={() => {
              const todo = todoItems?.todoItems.find(
                (todo) =>
                  todo.type === TodoItemType.ReviewDocument && !todo.isCompleted
              );

              if (todo) {
                markTodoAsCompleted(todo, token);
                refetch();
              }
            }}
            withoutActions={!isOrganizer || pageView !== "organizer"}
          />
        ))
        .concat(
          documents.map((item) => (
            <Document
              key={item.url}
              url={item.url}
              img={pdfIcon}
              name={item.name}
              type={"document"}
              id={item.id}
              eventId={event?.id}
              onDelete={() => {
                setDocuments((prevState) =>
                  prevState.filter((it) => it.id !== item.id)
                );
              }}
              withoutActions={!isOrganizer || pageView !== "organizer"}
            />
          ))
        )
        .concat(
          links.map((item) => (
            <Document
              key={item.url}
              url={item.url}
              img={fileTypeIcon}
              name={item.url}
              type={"link"}
              id={item.id}
              eventId={event?.id}
              onEdit={() => {
                setLinks((prevState) =>
                  prevState.filter((it) => it.id !== item.id)
                );
                setIsAttachDocumentModalOpen(true);
                setSelectedFileModal({
                  id: 1,
                  name: "Add links",
                  img: linkIcon,
                });
                setCurrentEditableLink(item);
              }}
              onDelete={() => {
                setLinks((prevState) =>
                  prevState.filter((it) => it.id !== item.id)
                );
              }}
              withoutActions={!isOrganizer || pageView !== "organizer"}
            />
          ))
        ),
    [links, documents, uploadedDocuments, todoItems]
  );
  const [agendaIsUpdated, setAgendaIsUpdated] = useState(false);
  const [showSelectCustomizationModal, setShowSelectCustomizationModal] =
    useState<string>("");
  const [reviewUserSelectionsModalIsOpen, setReviewUserSelectionsModalIsOpen] =
    useState(false);
  const [copyAddressModalIsOpen, setCopyAddressModalIsOpen] = useState(false);
  const [
    reviewAttendeesSelectionsModalIsOpen,
    setReviewAttendeesSelectionsModalIsOpen,
  ] = useState(false);
  const [attendeesSelections, setAttendeesSelections] = useState<{
    agendaBlockName: string;
    onOrder: () => Promise<void>;
    restaurantInfo: {
      name: string;
      address: string;
      cuisines: string;
      distance: string;
    };
    ordersCount: number;
    attendeesOrder: {
      attendeeName: string;
      selections: string[];
    }[];
    total: number;
    taxRate: number;
  }>({
    agendaBlockName: "",
    onOrder: async () => {},
    restaurantInfo: {
      name: "",
      address: "",
      cuisines: "",
      distance: "",
    },
    ordersCount: 0,
    attendeesOrder: [
      {
        attendeeName: "",
        selections: [],
      },
    ],
    total: 0,
    taxRate: 0,
  });
  const { eventAgendaMealBlockMenu, setEventAgendaMealBlockMenu } =
    useContext(MenuValue);

  useEffect(() => {
    const timer = setTimeout(async () => {
      const data = await fetchAttendeesNetwork({
        skip: 0,
        take: 10,
        fetchEvents: false,
        token,
        search: searchValue.length ? searchValue : undefined,
      });
      setAttendeesNetwork(data);
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue]);

  useEffect(() => {
    setLinks([]);
    setDocuments([]);
  }, []);

  useEffect(() => {
    if (userDeleted) {
      setAttendees((prevState) => {
        if (prevState !== "isFetching") {
          return {
            attendees: prevState!.attendees.filter(
              (contact) => contact.id !== userDeleted
            ),
            count: prevState!.attendees.filter(
              (contact) => contact.id !== userDeleted
            ).length,
          };
        }

        return { attendees: [], count: 0 };
      });

      setUserDeleted(null);
    }
  }, [userDeleted]);

  useEffect(() => {
    if (selectedRSVP !== event?.rsvp) {
      setSelectedRSVP(event?.rsvp);
    }

    if (event) {
      setIsEventHaveAlreadyPassed(
        +new Date(event.startTime).valueOf() < +new Date().valueOf()
      );
    } else {
      setIsEventHaveAlreadyPassed(false);
    }

    setEventLogo(event?.pattern || logoImage.PATH);
    setEventLogoColor(event?.color || logoColor.BLUE);

    setIsOrganizer(!!event?.isOrganizer);
  }, [event]);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  useEffect(() => {
    if (!isUsersInvitedQueryWorks) {
      setAttendees("isFetching");
      fetchInvitedAttendees({
        token,
        eventId: event!.id,
        take: 20,
        skip: 0,
      }).then((response) => {
        if (response) {
          setAttendees(response);
        }
      });
    }
  }, [isUsersInvitedQueryWorks]);

  useEffect(() => {
    if (isDocumentsUploaded) {
      fetchEvent({ slug, token }).then((response) => {
        queryClient.setQueryData(["event", slug], {
          data: {
            ...response,
          },
        });
      });

      setIsDocumentsUploaded(false);
    }
  }, [isDocumentsUploaded]);

  useEffect(() => {
    if (event && event.documents) {
      const documents: {
        document: {
          id: number;
          isUploaded: boolean;
          key: string;
          originalFileName: string;
          url: string | null;
        };
        url?: string | undefined;
      }[] = [];
      Promise.all(
        event.documents.map((document) =>
          fetchDocument({ documentId: document.id, token, id: event.id }).then(
            (document) => {
              if (document) {
                documents.push(document);
              }
            }
          )
        )
      ).then(() => setUploadedDocuments(documents));
    }

    if (event) {
      const agenda = editTime(
        event.agenda
          .sort((it1, it2) => it1.index - it2.index)
          .map((item, index) => ({
            id: `${item.id}`,
            icon:
              item.type === AgendaBlockType.Break
                ? clockPlusIcon
                : item.type === AgendaBlockType.Meal
                ? happyFaceIcon
                : presentationIcon,
            name: item.name,
            description: item.description,
            duration: durations.find(
              (duration) => duration.value === item.duration
            ) || {
              id: 1,
              name: `${item.duration} min`,
              value: item.duration,
            },
            startTime:
              getEventTime(event!.startTime, event.timezoneName)?.name || "",
            endTime: event.endTime
              ? getEventTime(event!.endTime, event.timezoneName)?.name ?? ""
              : "",
            type: item.type,
            index,
            mealBlockId: item.mealBlockId,
            isEditable: item.isEditable,
            finalizedUsersId: item.finalizedUsersIds?.length,
          })),
        getEventTime(event!.startTime, event.timezoneName)?.name ?? ""
      )!;
      setAgenda(agenda);
      const agendaSubBlocks: {
        id: string;
        name: string;
        startTime: string;
        endTime: string;
        duration: {
          id: number;
          name: string;
          value?: number | undefined;
        } | null;
        index: number;
        docsId: number[];
        agendaItemId: string;
      }[] = [];

      event.agenda.map((block) =>
        agendaSubBlocks.push(
          ...block.sections?.map((section) => ({
            id: `${section.id}`,
            name: section.name,
            startTime: "",
            endTime: "",
            duration:
              smallDurations.find(
                (duration) => duration.value === section.duration
              ) || null,
            index: section.index,
            docsId: section.documents?.map((doc) => +doc),
            agendaItemId: `${block.id}`,
          }))
        )
      );

      setSubBlocks(
        editSubBlockTime(
          agendaSubBlocks,
          getEventTime(event!.startTime, event.timezoneName)?.name || null,
          agenda,
          setSubBlocks
        ).sort((it1, it2) => it1.index - it2.index) || []
      );
    }
  }, [event]);

  useEffect(() => {
    if (agendaIsUpdated) {
      onAgendaUpdate();
    }
  }, [agendaIsUpdated]);

  function scrollFunction() {
    if (
      Math.ceil(document.getElementById("scrolledInfo")?.scrollTop || 0) >= 1
    ) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  }

  const onAgendaUpdate = async () => {
    const agendaItems = agenda.map((item, i) => ({
      id: +item.id ? +item.id : undefined,
      name: item.name,
      index: i,
      description: item.description,
      duration: item.duration.value || 0,
      type: item.type,
      dayId: event!.days[0].id,
      mealBlockId: item.mealBlockId,
    }));

    const response = await editEventAgenda({
      dayId: event!.id,
      body: agendaItems,
      token,
    });
    if (response?.status === 200 || response?.status === 201) {
      response.data
        .sort((item1, item2) => item1.index - item2.index)
        .map(async (agendaItem, index) => {
          const itemSubBlocks = subBlocks.filter(
            (subBlock) =>
              agendaItem.id && subBlock.agendaItemId === `${agendaItem.id}`
          );

          if (
            agendaItem.type === AgendaBlockType.Session &&
            itemSubBlocks.length &&
            agendaItem.id
          ) {
            if (!!agendaItem.sections.length) {
              editAgendaSubBlocks({
                agendaItemId: agendaItem.id,
                token,
                subBlocks: itemSubBlocks.map((subBlock) => ({
                  name: subBlock.name,
                  duration: subBlock.duration?.value,
                  index: subBlock.index,
                  filesOrLinks: "",
                })),
              });
            } else {
              postAgendaSubBlocks({
                agendaItemId: agendaItem.id,
                token,
                subBlocks: itemSubBlocks.map((subBlock) => ({
                  name: subBlock.name,
                  duration: subBlock.duration?.value,
                  index: subBlock.index,
                  filesOrLinks: "",
                })),
              });
            }
          }

          if (
            agendaItem.type === AgendaBlockType.Meal &&
            newSelectedRestaurant &&
            (newOrders.find((order) => order.agendaBlockId === index)
              ?.productId ||
              selectedAvailableMealsForAttendees.find(
                (order) => order.id === index
              )?.products.length)
          ) {
            const mealId = agendaItem.mealBlockId;
            const selectedRestaurant = restaurantsForOrders.find(
              (item) => item.agendaBlockId === index
            );

            if (mealId) {
              return;
            }

            const postedMealResponse = await postMeals({
              body: {
                type: !!isPickup.find((it) => it.id === index)?.state
                  ? MealBlockType.Pickup
                  : MealBlockType.Delivery,
                choice: !!creatorIsOrder.find((it) => it.id === index)?.state
                  ? MealBlockChoice.PreSelected
                  : MealBlockChoice.Organizer,
                agendaItemId: agendaItem.id!,
              },
              token,
            });

            const restaurantQueryBody =
              typeof newSelectedRestaurant?.id === "number"
                ? { customRestaurantId: newSelectedRestaurant.id }
                : { restaurantId: newSelectedRestaurant?.id };
            const postedRestaurantResponse = await postRestaurants({
              id: postedMealResponse!.id,
              body: restaurantQueryBody,
              token,
            });

            if (!creatorIsOrder.find((it) => it.id === index)?.state) {
              Promise.all(
                orders.map(async (order) => {
                  for (let i = 0; i < order.count; i++) {
                    await postMealsSelections({
                      id: postedMealResponse!.id,
                      body: {
                        customizations: order.customizations,
                        productId: order.productId,
                        markedPrice: order.markedPrice,
                        notes: order.notes,
                      },
                      token,
                    });
                  }
                })
              );
            } else if (!!creatorIsOrder.find((it) => it.id === index)?.state) {
              const currentOrderIds: { id: string; name: string }[] = [];

              selectedAvailableMealsForAttendees
                .filter((order) => order.id === index)
                .map((item) => {
                  currentOrderIds.push(...item.products);
                });

              await postPreSelect({
                id: postedMealResponse!.id,
                body: {
                  products: currentOrderIds,
                },
                token,
              });
            }
          }
        });
    }

    setAgendaIsUpdated(false);
    // refetchEvent();
  };

  async function onRSVPSelect(item: AttendeeRSVP) {
    if (!token) {
      setNotLogginedUserIsOpen(true);

      return;
    }

    if (!event) return;

    if (eventSlug === event.slug) {
      setEventSlug("");
    }

    let rsvpTodo: any | TodoItem = undefined;

    if (todoItems?.todoItems) {
      rsvpTodo =
        todoItems.todoItems[
          todoItems.todoItems.findIndex(
            (item) => item.type === TodoItemType.RespondToInvite
          )
        ];
    }

    await postAttendeeRSVP({ body: { rsvp: item, eventId: event.id }, token });

    if (rsvpTodo) {
      await markTodoAsCompleted(rsvpTodo, token);
    }

    queryClient.setQueryData(["event", event.slug], {
      ...event,
      rsvp: item,
    });

    refetch();
  }

  if (!event) {
    return null;
  }

  return (
    <div
      className={`eventDetails_container ${
        !sizeIsDesktop ? "flex-column" : "flex-row"
      }`}
    >
      {reviewUserSelectionsModalIsOpen
        ? createPortal(
            <ReviewSelectionsInviteeView
              title={"Your meal selections"}
              setIsOpen={setReviewUserSelectionsModalIsOpen}
              restaurantInfo={{
                name: selectedRestaurant?.name || "",
                distance: `${selectedRestaurant?.distance}mi` || "",
                address: selectedRestaurant?.address || "",
                cuisines: selectedRestaurant?.cuisines?.join(", ") || "",
              }}
              openMealEdit={(mealId: string) => {
                setSelectMealsModalIsOpen(true);
                setShowSelectCustomizationModal(mealId);
              }}
              menu={eventAgendaMealBlockMenu}
              setPreOrder={setPreOrder}
              mealBlockId={mealBlockId}
              setOrders={setOrders}
              agendaBlockId={currentAgendaBlockId || 0}
              orders={orders}
              setShowSelectRestaurantModal={setSelectMealsModalIsOpen}
              todoItems={todoItems}
              eventId={event.id}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      {createPortal(
        <PreviewPreselectedMealsInviteesMeals
          selectedRestaurant={selectedRestaurant}
          showSelectMealModal={selectMealsModalIsOpen}
          menu={eventAgendaMealBlockMenu}
          preOrder={preOrder}
          setPreOrder={setPreOrder}
          mealBlockId={mealBlockId}
          setReviewUserSelectionsModalIsOpen={
            setReviewUserSelectionsModalIsOpen
          }
          setSelectMealsModalIsOpen={setSelectMealsModalIsOpen}
          asyncOnAddMeal={async () => {
            if (mealBlockId && orders.length) {
              const todo = todoItems?.todoItems.find(
                (todo) => todo.mealBlockId === mealBlockId
              );
              const selectionsToPost: {
                customizations: {
                  customizationId: string;
                  optionId: string[];
                  markedPrice: number;
                }[];
                productId: string;
                markedPrice: number;
                notes: string;
              }[] = [];
              orders.forEach((order) => {
                for (let i = 0; i < order.count; i++) {
                  selectionsToPost.push({
                    customizations: order.customizations,
                    productId: order.productId,
                    markedPrice: order.markedPrice,
                    notes: order.notes,
                  });
                }

                return order;
              });

              await postMealsSelectionsBulk({
                id: mealBlockId,
                body: {
                  selections: selectionsToPost,
                },
                token,
              }).then(() => {
                if (todo) {
                  finalizeMealBlock({
                    token,
                    id: mealBlockId,
                    eventId: event.id,
                  });
                  markTodoAsCompleted(todo, token);
                }
              });

              onRSVPSelect(AttendeeRSVP.Yes);
              setOrders([]);
              setSelectMealsModalIsOpen(false);
              refetch();
            }
          }}
          isDesktopSize={sizeIsDesktop}
          setOrders={setOrders}
          orders={orders}
          agendaBlockId={currentAgendaBlockId || 0}
          showSelectCustomizationModal={showSelectCustomizationModal}
          setShowSelectCustomizationModal={setShowSelectCustomizationModal}
        />,
        document.getElementById("modal") as HTMLElement
      )}
      {showAgendaModal
        ? createPortal(
            <ModalAgenda
              eventStartTime={getEventTime(
                event!.startTime,
                event.timezoneName
              )}
              isEdit={true}
              setIsEdit={() => {}}
              currentItem={currentItem}
              setIsOpen={setShowAgendaModal}
              setAgenda={setAgenda}
              agenda={agenda}
              location={`${event.location}`}
              latitude={event.locationLatitude ? +event.locationLatitude : 0}
              longitude={event.locationLongitude ? +event.locationLongitude : 0}
              setMealSelectedOrders={() => {}}
              dayId={event!.days[0].id}
              setIsPickup={setIsPickup}
              isPickup={isPickup}
              creatorIsOrder={creatorIsOrder}
              setCreatorIsOrder={setCreatorIsOrder}
              setSelectedRestaurant={setNewSelectedRestaurant}
              selectedRestaurant={newSelectedRestaurant}
              setOrders={setNewOrders}
              orders={newOrders}
              setMealBlocksWithoutMealsSelection={() => {}}
              setSelectedAvailableMealsForAttendees={
                setSelectedAvailableMealsForAttendees
              }
              selectedAvailableMealsForAttendees={
                selectedAvailableMealsForAttendees
              }
              agendaBlockId={currentItem!.index}
              restaurantsForOrders={restaurantsForOrders}
              setRestaurantsForOrders={setRestaurantsForOrders}
              subBlocks={subBlocks}
              setSubBlocks={setSubBlocks}
              setIsAgendaUpdated={() => setAgendaIsUpdated(true)}
              currentEventId={event.id}
            />,
            document.getElementById("modal") as HTMLElement
          )
        : null}
      <RenderModals
        notLogginedUserIsOpen={notLogginedUserIsOpen}
        setNotLogginedUserIsOpen={setNotLogginedUserIsOpen}
        slug={slug}
        reviewAttachmentsIsOpen={reviewAttachmentsIsOpen}
        setReviewAttachmentsIsOpen={setReviewAttachmentsIsOpen}
        attachments={attachments}
        isAttachDocumentModalOpen={isAttachDocumentModalOpen}
        selectedFileModal={selectedFileModal}
        setSelectedFileModal={setSelectedFileModal}
        setIsAttachDocumentModalOpen={setIsAttachDocumentModalOpen}
        eventId={event.id}
        setDocuments={setDocuments}
        sizeIsDesktop={sizeIsDesktop}
        uploadedDocuments={uploadedDocuments}
        setLinks={setLinks}
        currentEditableLink={currentEditableLink}
        createNewUserIsOpen={createNewUserIsOpen}
        setCreateNewUserIsOpen={setCreateNewUserIsOpen}
        attendeesNetwork={attendeesNetwork}
        orderMealsModalIsOpen={orderMealsModalIsOpen}
        setOrderMealsModalIsOpen={setOrderMealsModalIsOpen}
        cartId={cartId}
        mealBlockIdForOrder={mealBlockIdForOrder}
        notificationModalIsOpen={notificationModalIsOpen}
        setNotificationModalIsOpen={setNotificationModalIsOpen}
        isEditLogoModalOpen={isEditLogoModalOpen}
        isOrganizer={isOrganizer}
        setIsEditLogoModalOpen={setIsEditLogoModalOpen}
        setEventLogo={setEventLogo}
        setEventLogoColor={setEventLogoColor}
        selectedAddAttendees={selectedAddAttendees}
        attendees={attendees}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setIsUsersInvitedQueryWorks={setIsUsersInvitedQueryWorks}
        setSelectedAddAttendees={setSelectedAddAttendees}
        reviewSelectionsOrganizerViewIsOpen={
          reviewAttendeesSelectionsModalIsOpen
        }
        setReviewSelectionsOrganizerViewIsOpen={
          setReviewAttendeesSelectionsModalIsOpen
        }
        attendeesOrderInfo={attendeesSelections}
        address={event.location}
        copyAddressModalIsOpen={copyAddressModalIsOpen}
        setCopyAddressModalIsOpen={setCopyAddressModalIsOpen}
        setSelectMealsModalIsOpen={setSelectMealsModalIsOpen}
      />
      <ReviewEventDetailsHeader
        pageView={pageView}
        setPageView={setPageView}
        isScrolled={isScrolled}
        isOrganizer={isOrganizer}
        setNotLogginedUserIsOpen={setNotLogginedUserIsOpen}
      />
      <div
        className="
      eventDetails_main-container eventDetails_main-container-noMarginTop 
      eventDetails_main-container-noMaxWidth eventDetails_main-container-withoutBorder 
       px-12 border-box py-12
    "
        style={{
          marginTop: sizeIsDesktop ? undefined : isScrolled ? "90px" : "112px",
          height: sizeIsDesktop ? "" : "100%",
          borderRadius: sizeIsDesktop ? undefined : "0",
        }}
      >
        <div
          className="eventDetails_main eventDetails_main-bgTransparent reviewEventDetailsPage paddingNone"
          onScroll={scrollFunction}
          id="scrolledInfo"
        >
          <LogoRepeat
            color={
              isEventHaveAlreadyPassed
                ? "pastEvent"
                : eventLogoColor
                ? eventLogoColor
                : undefined
            }
            image={
              !eventLogo
                ? pathIcon
                : getLogoRepeatImage({
                    selectedColor: isEventHaveAlreadyPassed
                      ? "pastEvent"
                      : eventLogoColor,
                    selectedPattern: eventLogo,
                  })
            }
            size={"lg"}
            position="absolute"
            positionTop={sizeIsDesktop ? "13%" : "0"}
          />
          <div className="reviewEventDetailsPage-main-content">
            <div
              className="flex flex-column gap-20"
              style={{ width: sizeIsDesktop ? "65%" : "100%" }}
            >
              <MainHero
                isOrganizer={isOrganizer}
                event={event}
                eventLogo={eventLogo}
                eventLogoColor={eventLogoColor}
                setIsEditLogoModalOpen={setIsEditLogoModalOpen}
                isFetching={isFetching}
              />
              {isFetching ? (
                <div className="flex fullHeight fullWidth items-center justifyCenter">
                  <Loader size="xl" />
                </div>
              ) : (
                <main className="reviewEventDetailsPage-main">
                  <div className={`eventDetails_main-content gap-32`}>
                    {isOrganizer && pageView === "organizer" ? (
                      <div className={`fullWidth flex items-center gap-16`}>
                        <div className="reviewEventDetailsPage-main-attendingInfoBlock">
                          <div className="reviewEventDetailsPage-main-attendingInfoBlock-header">
                            <p className="reviewEventDetailsPage-main-attendingInfoBlock-header-label">
                              Attending
                            </p>
                            <img
                              src={usersPlusIcon}
                              alt=""
                              className="reviewEventDetailsPage-main-attendingInfoBlock-header-img"
                            />
                          </div>
                          <h3 className="reviewEventDetailsPage-main-attendingInfoBlock-title">
                            {
                              event.attendees.filter(
                                (attendee) => attendee.rsvp === AttendeeRSVP.Yes
                              ).length
                            }
                          </h3>
                        </div>
                        <div className="reviewEventDetailsPage-main-attendingInfoBlock">
                          <div className="reviewEventDetailsPage-main-attendingInfoBlock-header">
                            <p className="reviewEventDetailsPage-main-attendingInfoBlock-header-label">
                              Not responded
                            </p>
                            <img
                              src={alertTriangle}
                              alt=""
                              className="reviewEventDetailsPage-main-attendingInfoBlock-header-img"
                            />
                          </div>
                          <h3 className="reviewEventDetailsPage-main-attendingInfoBlock-title">
                            {
                              event.attendees.filter(
                                (attendee) => attendee.rsvp === null
                              ).length
                            }
                          </h3>
                        </div>
                        <div className="reviewEventDetailsPage-main-attendingInfoBlock">
                          <div className="reviewEventDetailsPage-main-attendingInfoBlock-header">
                            <p className="reviewEventDetailsPage-main-attendingInfoBlock-header-label">
                              Not going
                            </p>
                            <img
                              src={userXIcon}
                              alt=""
                              className="reviewEventDetailsPage-main-attendingInfoBlock-header-img"
                            />
                          </div>
                          <h3 className="reviewEventDetailsPage-main-attendingInfoBlock-title">
                            {
                              event.attendees.filter(
                                (attendee) => attendee.rsvp === AttendeeRSVP.No
                              ).length
                            }
                          </h3>
                        </div>
                      </div>
                    ) : null}
                    {agenda ? (
                      <ReviewAgendaSection
                        agenda={agenda.map((item) => {
                          const updatedItem: {
                            id: string;
                            icon: string;
                            name: string;
                            description: string;
                            duration: {
                              id: number;
                              name: string;
                              value?: number | undefined;
                            };
                            startTime: string;
                            endTime: string;
                            type: AgendaBlockType;
                            mealBlockId?: number | undefined;
                            index: number;
                            isEditable: boolean;
                            onClick: (() => Promise<void>) | undefined;
                          } = {
                            ...item,
                            onClick: getAgendaItemOnClickProps(
                              isOrganizer,
                              item,
                              setSelectedRestaurant,
                              setEventAgendaMealBlockMenu,
                              setMealBlockId,
                              setReviewUserSelectionsModalIsOpen,
                              setNotLogginedUserIsOpen,
                              setCurrentAgendaBlockId,
                              event.id,
                              setAttendeesSelections,
                              setReviewAttendeesSelectionsModalIsOpen,
                              setOrderMealsModalIsOpen,
                              setCopyAddressModalIsOpen,
                              setMealBlockIdForOrder,
                              setCartId
                            ),
                          };

                          return updatedItem;
                        })}
                        onDelete={(agendaName: string, id: string) =>
                          setNotificationModalIsOpen({
                            name: "Delete agenda item",
                            description: `Are you sure you want to delete ${agendaName} from this event? `,
                            firstButtonName: "Cancel",
                            secondButtonName: "Delete",
                            onFirstButtonClick: () =>
                              setNotificationModalIsOpen(null),
                            onSecondButtonClick: () => {
                              setAgendaIsUpdated(true);
                              setAgenda((prevState) =>
                                prevState.filter((item) => item.id !== id)
                              );
                              setNotification({
                                title: `Agenda item has been deleted`,
                                description: `Your ${agendaName} has been successfully deleted.`,
                                isError: false,
                              });
                              setNotificationModalIsOpen(null);
                            },
                          })
                        }
                        invitedUsers={event.attendees.length}
                        withActions={isOrganizer}
                        setAgenda={setAgenda}
                        setCurrentItem={setCurrentItem}
                        setShowAgendaModal={setShowAgendaModal}
                        subBlocks={subBlocks}
                        setSubBlocks={setSubBlocks}
                        setCurrentMealBlockId={setMealBlockId}
                      />
                    ) : null}
                  </div>
                </main>
              )}
            </div>
            <div
              className={`flex flex-column gap-16 positionRelative fullWidth ${
                sizeIsDesktop ? "max-w-320" : ""
              }`}
            >
              <EventActions
                isPublished={event.isPublished}
                isOrganizer={isOrganizer}
                slug={event.slug}
                agendaLength={agenda.length}
                eventId={event.id}
                setPageView={setPageView}
                setNotificationModalIsOpen={setNotificationModalIsOpen}
                selectedRSVP={selectedRSVP}
                setSelectedRSVP={setSelectedRSVP}
                onRSVPSelect={onRSVPSelect}
                todoItems={todoItems}
                setNotLogginedUserIsOpen={setNotLogginedUserIsOpen}
                setEventAgendaMealBlock={setEventAgendaMealBlockMenu}
                setSelectedRestaurant={setSelectedRestaurant}
                setMealBlockId={setMealBlockId}
                setSelectMealsModalIsOpen={setSelectMealsModalIsOpen}
                setAgendaBlockId={setCurrentAgendaBlockId}
              />
              {pageView === "attendee" || !isOrganizer ? (
                <AttendeeActions
                  location={event.location}
                  lng={event.locationLongitude ? +event.locationLongitude : 0}
                  lat={event.locationLatitude ? +event.locationLatitude : 0}
                />
              ) : null}
              {isOrganizer ? (
                <EventInfoPreview
                  title={"Meals"}
                  emptyStateTilte={"No meals"}
                  emptyStateImg={checkCircleIcon}
                  minHeight="min-content"
                  count={1}
                  items={agenda
                    .filter((item) => !!item.mealBlockId)
                    .map((item) => (
                      <UsersThatMadeOrderCountContainer bigPadding>
                        <UsersThatMadeOrderCountWidget
                          ordered={item.finalizedUsersId || 0}
                          invited={event.attendees.length}
                          onArrowClick={async () => {
                            await openSelectMealsModal({
                              mealBlockId: item.mealBlockId!,
                              setAgendaBlockId: setCurrentAgendaBlockId,
                              setEventAgendaMealBlock:
                                setEventAgendaMealBlockMenu,
                              setMealBlockId,
                              setSelectedRestaurant,
                              setSelectMealsModalIsOpen,
                            });
                          }}
                          agendaBlockName={item.name}
                        />
                      </UsersThatMadeOrderCountContainer>
                    ))}
                />
              ) : null}
              <EventInfoPreview
                title={"To do's"}
                emptyStateTilte={"No tasks to do"}
                emptyStateImg={checkCircleIcon}
                minHeight="min-content"
                count={
                  todoItems?.todoItems.filter((todo) => !todo.isCompleted)
                    .length || 0
                }
                items={
                  GetTodoItems({
                    todoItems,
                    onRSVPSelect,
                    setMealBlockId,
                    setSelectedRestaurant,
                    setSelectMealsModalIsOpen,
                    setEventAgendaMealBlock: setEventAgendaMealBlockMenu,
                    setNotLogginedUserIsOpen,
                    setMealBlockIdForOrder,
                    setCartId,
                    setOrderMealsModalIsOpen,
                    setAgendaBlockId: setCurrentAgendaBlockId,
                    setCopyAddressModalIsOpen,
                    agendaLength: event.agenda.length,
                    refetch: () => {
                      refetchEvent();
                      refetch();
                    },
                  }) || []
                }
              />
              <div className="positionRelative flex flex-column gap-16 items-center justifyCenter">
                <EventInfoPreview
                  title={"Attachments"}
                  emptyStateTilte={"No attachments"}
                  emptyStateImg={fileAttachmentIcon}
                  items={
                    attachments
                      ? attachments.length >= 3
                        ? attachments.slice(0, 3)
                        : attachments
                      : []
                  }
                  count={attachments.length || 0}
                  addButtonAction={
                    isOrganizer && pageView === "organizer"
                      ? () => {
                          setIsSelectFileModalOpen((prevState) => !prevState);
                          setIsAttachDocumentModalOpen(true);
                        }
                      : undefined
                  }
                  viewAllButton={
                    attachments.length >= 3
                      ? () => setReviewAttachmentsIsOpen(true)
                      : undefined
                  }
                />
                {isSelectFileModalOpen ? (
                  <DropdownMenu
                    content={[
                      { id: 1, name: "Add links", img: linkIcon },
                      { id: 2, name: "Upload files", img: uploadCloudIcon },
                    ]}
                    selectedItem={selectedFileModal}
                    setSelectedItem={setSelectedFileModal}
                    setShow={() => {
                      setIsSelectFileModalOpen(false);
                    }}
                    size="max-content"
                    positionRight={"7px"}
                    positionTop={"50px"}
                    zIndex="100"
                  />
                ) : null}
              </div>
              {!!todoItems?.todoItems.filter((todo) => todo.isCompleted)
                .length ? (
                <div
                  className="homePage-content-todos homePage-content-todos-min-w-200 bgWhite z100"
                  style={{ height: "auto", minHeight: "min-content" }}
                >
                  <div className="homePage-content-todos-header">
                    <div className="homePage-content-todos-header-topic">
                      <h3 className="homePage-content-todos-header-topic-title">
                        Completed tasks
                      </h3>
                    </div>
                    <img src="" alt="" />
                  </div>
                  {todoItems?.todoItems.filter((todo) => todo.isCompleted)
                    .length ? (
                    <div className="homePage-content-todos-content">
                      {todoItems.todoItems
                        .filter((todo) => todo.isCompleted)
                        .map((todo) => (
                          <div
                            className="flex items-center gap-4"
                            key={todo.id}
                          >
                            <img
                              src={checkSuccessIcon}
                              alt=""
                              width={16}
                              height={16}
                            />
                            <p className="text-xs font-semibold color-gray-950">
                              {todo.type === TodoItemType.RespondToInvite
                                ? "Respond to the event invite"
                                : todo.type === TodoItemType.MealOrder
                                ? `Order meal for ${todo.event.name}`
                                : todo.type === TodoItemType.MealSelection
                                ? `Select meal for ${todo.event.name}`
                                : todo.type === TodoItemType.SubmitEvent
                                ? "Your event hasn’t been submitted."
                                : todo.type === TodoItemType.ReviewDocument
                                ? "Please, review the document"
                                : ""}
                            </p>
                          </div>
                        ))}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
