import calendarIcon from "../../assets/images/calendar.svg";
import { Button } from "../Buttons/Button";
import DatePickerLarge from "./DatePickerLarge";
import moment from "moment";
import errorIcon from "../../assets/images/alert-circle.svg";

interface Props {
  type: "double" | "small" | "smallWithTodayFeat";
  top?: string | null;
  bottom?: string | null;
  right?: string | null;
  left?: string | null;
  placeholder: string;
  isError?: boolean;
  firstDate: moment.Moment | null;
  setFirstDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  secondDate?: moment.Moment | null;
  setSecondDate?: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  setShowOtherPickers: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen: boolean;
  selectedDayOnlyFromToday: boolean;
  selectSecondDateFrom?: moment.Moment | null;
  onApply?: () => void;
  paddingNone?: boolean;
  withoutButton?: boolean;
}

export default function Date({
  type,
  bottom,
  left,
  right,
  top,
  placeholder,
  isError,
  firstDate,
  secondDate,
  setFirstDate,
  setSecondDate,
  setShowOtherPickers,
  isOpen,
  setIsOpen,
  selectedDayOnlyFromToday,
  onApply,
  selectSecondDateFrom,
  paddingNone,
  withoutButton,
}: Props) {
  return (
    <div className="calendar">
      {withoutButton ? null : (
        <div className="flex items-start flex-column fullWidth gap-12">
          <Button
            buttonType="regular"
            size="md"
            hierarchy={isError ? "newDesign-error" : "newDesign-gray"}
            onClick={() => {
              setShowOtherPickers && setShowOtherPickers();
              setIsOpen((prevState) => !prevState);
            }}
            newDesignPaddingNone={paddingNone}
            fullWidth
          >
            <div className="flex flex-column gap-8 items-start">
              {firstDate !== null ? (
                <p className="text-sm font-medium">Date of event</p>
              ) : null}
              <div
                className={`openButton ${
                  firstDate
                    ? ""
                    : "openButton-color-gray openButton-width-content"
                } text-sm`}
              >
                <img
                  src={calendarIcon}
                  alt="calendar icon"
                  className="calendarIcon"
                />
                <p
                  className={`text-md font-medium color-gray-600 ${
                    isError ? "textError" : ""
                  }`}
                >
                  {!firstDate && !secondDate
                    ? placeholder
                    : `${firstDate?.format("ll")} ${secondDate ? "-" : ""} ${
                        secondDate ? secondDate?.format("ll") : ""
                      }`}
                </p>
              </div>
            </div>
          </Button>
          {isError && (
            <div className="flex items-center gap-8">
              <img src={errorIcon} alt="error" />
              <p className="inputNew-error-text">Please, select a date</p>
            </div>
          )}
        </div>
      )}
      <DatePickerLarge
        firstDate={firstDate}
        setFirstDate={setFirstDate}
        secondDate={secondDate}
        setSecondDate={setSecondDate}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        type={type}
        bottom={bottom}
        left={left}
        right={right}
        top={top}
        selectedDayOnlyFromToday={selectedDayOnlyFromToday}
        onApply={onApply}
        selectSecondDateFrom={selectSecondDateFrom}
      />
    </div>
  );
}
