import React from "react";
import closeIcon from "../../assets/images/x-close-black.svg";
import copyIcon from "../../assets/images/copy-02.svg";
import pinIcon from '../../assets/images/marker-pin-01.svg';

interface Props {
  setIsOpen: React.Dispatch<boolean>;
  address: string;
}

export default function CopyAddressModal({ setIsOpen, address }: Props) {
  return (
    <div className="modalNotification" style={{ zIndex: '10001' }}>
      <div className="modalNotification-container" style={{ maxWidth: '600px' }}>
        <div
          className="modalNotification-header modalNotification-header-error"
          style={{ marginBottom: "0px" }}
        >
          <div className="modalNotification-header-actions">
            <p className="text-xl font-semibold color-gray-950">Add event address</p>
            <img
              src={closeIcon}
              alt="close icon"
              className="modalNotification-header-actions-close"
              onClick={() => setIsOpen(false)}
            />
          </div>
          <div className="modalNotification-header-info">
            <p>
              Please make sure to add the same event address on your event into
              the <span className="font-semibold color-gray-950">MealMe</span>
            </p>
          </div>
          <div autoFocus className={`websiteInputWrapper input-lg fullWidth`}>
            <div className={`input`} style={{ borderColor: '#444CE7' }}>
              <div className="flex items-center gap-4">
                <img src={pinIcon} alt="" width={16} height={16} />
                <p className="text-xs font-semibold color-gray-900">{address}</p>
              </div>
              <button
                className="copyButton websiteCopyFeatButton text-md font-semibold border-none paddingNone"
                onClick={() => {
                  navigator.clipboard.writeText(address);
                  setIsOpen(false)
                }}
              >
                <img src={copyIcon} alt="copy icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
