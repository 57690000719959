import { useQuery } from "@tanstack/react-query";
import { fetchEvents } from "../../httpQueries/http";
import { EventsFilter } from "../../types/eventsFilter";
import { getTimeFromMinutes } from "../../utilities/getTimeFromMinutes";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import moment from "moment";
import { Button } from "../Buttons/Button";
import logo from "../../assets/images/path90.svg";
import EventReviewBlockV2 from "../EventBlock/EventReviewBlockV2";
import { getTimeDiffence } from "../../utilities/getTimeDifference";
import { getEventTime } from "../../utilities/getEventStartTime";

export default function EventsGallery({
  refetchEvents,
}: {
  refetchEvents: boolean;
}) {
  const token = localStorage.getItem("token");
  const today = new Date();
  const { data: events, fetchStatus } = useQuery({
    queryKey: ["events", token, refetchEvents],
    queryFn: () =>
      fetchEvents({
        start: new Date(
          +today.getFullYear(),
          +today.getMonth(),
          +today.getDate(),
          0,
          0,
          0,
          0
        ).valueOf(),
        end: new Date(
          +today.getFullYear(),
          +today.getMonth() + 1,
          +today.getDate(),
          23,
          59,
          59,
          999
        ).valueOf(),
        token,
        eventFilter: EventsFilter.All,
        isPublished: true,
        isActive: true,
      }),
  });
  const navigate = useNavigate();

  return (
    <div
      className={`eventsGallery overflowYScroll ${
        events?.events.length && events.events.length >= 2
          ? "eventsGallery-minHeight410px"
          : ""
      }`}
    >
      <div className="flex fullWidth items-center spaceBetween">
        <div className="flex items-center gap-4">
          <p className="eventsGallery-title">Upcoming events</p>
          <p className="eventsGallery-count">{events?.events.length}</p>
        </div>
        <Button
          size={"md"}
          hierarchy={"linkColor"}
          buttonType={"regular"}
          onClick={() => navigate("/events?filter=organizer")}
        >
          View all
        </Button>
      </div>
      {fetchStatus === "fetching" ? (
        <div className="flex fullWidth fullHeight items-center justifyCenter">
          <Loader size={"xl"} />
        </div>
      ) : (
        <div className="eventsGallery-events">
          <div className="eventsGallery-events" id="container">
            {events?.events.length ? (
              events.events.map((event) => (
                <EventReviewBlockV2
                  slug={event.slug}
                  name={event.name}
                  date={moment(event.startDate).format("ll")}
                  startTime={
                    getEventTime(event!.startTime, event.timezoneName)?.name ||
                    ""
                  }
                  endTime={
                    event.endTime
                      ? getEventTime(event!.endTime, event.timezoneName)?.name
                      : undefined
                  }
                  location={event.location}
                  onClick={() => navigate(`/events/${event.slug}`)}
                  color={
                    +new Date(event.startTime).valueOf() < +new Date().valueOf()
                      ? "pastEvent"
                      : event.color
                  }
                  pattern={event.pattern}
                  description={event.description}
                  timeDifference={event.totalDuration}
                />
              ))
            ) : (
              <div className="noEventsFoundEventsGallery">
                <div className="noEventsFoundEventsGallery-logo">
                  <img src={logo} alt="" />
                </div>
                <div className="flex flex-column gap-4 items-center justifyCenter">
                  <h3 className="noEventsFoundEventsGallery-title">
                    No upcoming events
                  </h3>
                  <p className="noEventsFoundEventsGallery-description">
                    You have no upcoming events
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
