import React from "react";

interface Props {
  ordered: number;
  invited: number;
}

export default function UsersThatMadeOrderCountAgendaBlock({
  ordered,
  invited,
}: Props) {
  return (
    <>
      <div className="eventDetailsWelcomePage-thingsTodo-todo-circle eventDetailsWelcomePage-thingsTodo-todo-circle-blue"></div>
      <p>
        {ordered} of {invited} have ordered food
      </p>
    </>
  );
}
