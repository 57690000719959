import React, { useEffect, useState } from "react";
import checkIcon from "../../assets/images/checkIconGray.svg";
import checkSuccessIcon from "../../assets/images/Check-icon-success.svg";
import logo from "../../assets/images/logo-lg-color.svg";
import { FormInput } from "../../components/Inputs/FormInput";
import { Button } from "../../components/Buttons/Button";
import { useWindowSize } from "@uidotdev/usehooks";
import { checkIsPasswordHasSpecialCharacter } from "../../utilities/passwordHasSpecialCharacter";
import { validateEmail } from "../../utilities/validateEmail";
import { Link, useNavigate } from "react-router-dom";
import AccountCreated from "./AccountCreated";
import ErrorMessage from "../../components/errorMessage/errorMessage";
import Loader from "../../components/Loader";
import { supabase } from "../../utilities/supabaseClient";
import { registerUser } from "../../httpQueries/http";

export default function SignUp() {
  const size = useWindowSize();
  const [sizeIsDesktop, setSizeIsDesktop] = useState(true);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const navigate = useNavigate();
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSizeIsDesktop(size.width! >= 768);
  }, [size.width]);

  async function RegisterUser() {
    if (!userEmail.trim().length || !validateEmail(userEmail.trim())) {
      setIsEmailError(true);
    } else if (userEmail.trim().length && validateEmail(userEmail.trim())) {
      setIsEmailError(false);
    }

    if (
      !(userPassword.trim().length >= 8) ||
      !checkIsPasswordHasSpecialCharacter(userPassword)
    ) {
      setIsPasswordError(true);
    } else if (
      userPassword.trim().length >= 8 &&
      checkIsPasswordHasSpecialCharacter(userPassword)
    ) {
      setIsPasswordError(false);
    }

    if (
      userEmail.trim().length &&
      validateEmail(userEmail.trim()) &&
      userPassword.trim().length >= 8 &&
      checkIsPasswordHasSpecialCharacter(userPassword) &&
      userName.length
    ) {
      setIsLoading(true);
      const { data } = await supabase().auth.signUp({
        email: userEmail.trim(),
        password: userPassword.trim(),
      });

      if (data.user) {
        try {
          const response = await registerUser({
            user: {
              fullName: userName,
              email: data.user.email!,
              id: data.user.id,
            },
          });

          if (response.status >= 200 && response.status < 400) {
            setIsError(false);
            setIsAccountCreated(true);
            setIsLoading(false);
          }

          if (response.status >= 400) {
            throw Error();
          }
        } catch {
          setIsLoading(false);
          setIsError(true);
        }
      }
    }
  }

  return (
    <>
      {isAccountCreated ? (
        <AccountCreated
          email={userEmail}
          setIsAccountCreated={setIsAccountCreated}
        />
      ) : (
        <div className="login">
          <div className="login-signIn">
            <div className="login-signIn-header">
              <img src={logo} alt="" className="login-signIn-logo" />
            </div>
            <div className="login-signIn-content items-center">
              <div className="flex gap-12 flex-column selfStart">
                <h3 className="login-signIn-title">Sign up</h3>
                <p className="login-signIn-description">
                  Start your 30-day free trial.
                </p>
              </div>
              {isError ? (
                <ErrorMessage
                  title="Email already exists"
                  description="There’s already an account with this email. Would you like to reset your password?"
                />
              ) : null}
              <div className="flex flex-column gap-24 fullWidth">
                <div className="flex gap-20 flex-column">
                  <FormInput
                    type="Text"
                    label="Name"
                    design="new"
                    fullWidth
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  <FormInput
                    type="Email"
                    label="Email"
                    design="new"
                    fullWidth
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                    isError={isEmailError}
                  />
                  <FormInput
                    type="Password"
                    label="Password"
                    design="new"
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                    isError={isPasswordError}
                  />
                </div>
                <div className="flex flex-column gap-12">
                  <div className="flex gap-8">
                    <img
                      src={
                        userPassword.trim().length >= 8
                          ? checkSuccessIcon
                          : checkIcon
                      }
                      alt=""
                    />
                    <p>Must be at least 8 characters</p>
                  </div>
                  <div className="flex gap-8">
                    <img
                      src={
                        checkIsPasswordHasSpecialCharacter(userPassword)
                          ? checkSuccessIcon
                          : checkIcon
                      }
                      alt=""
                    />
                    <p>Must contain one special character</p>
                  </div>
                </div>
                <div className="flex flex-column gap-16">
                  {isLoading ? (
                    <div className="flex justifyCenter fullWidth">
                      <Loader size="sm" scale="2.5" />
                    </div>
                  ) : (
                    <Button
                      buttonType="regular"
                      hierarchy="newDesign-primary"
                      newDesignPaddingNone
                      size="lg"
                      fullWidth
                      contentCenter
                      asyncOnClick={RegisterUser}
                    >
                      Get started
                    </Button>
                  )}
                </div>
              </div>
              <div className="flex gap-4">
                <p className="whiteSpaceNoWrap">Already have an account?</p>
                <Button
                  buttonType="regular"
                  hierarchy="linkColor"
                  size="md"
                  paddingNone
                  onClick={() => navigate("/login")}
                >
                  <p className="whiteSpaceNoWrap">Log in</p>
                </Button>
              </div>
              <div
                className={`flex gap-4 items-center flex-wrap justifyCenter ${
                  !sizeIsDesktop ? "flex-column" : ""
                }`}
              >
                <p className="textCenter">
                  By creating an account you’re accepting to our
                </p>
                <Button
                  buttonType="regular"
                  hierarchy="linkColor"
                  size="md"
                  paddingNone
                >
                  <Link className="whiteSpaceNoWrap" to={'/terms'} target="_blank" style={{ textDecoration: 'none' }}>Terms of service</Link>
                </Button>
              </div>
            </div>
            <p className="login-signIn-copyright">© Gatherwise Inc {new Date().getFullYear()}</p>
          </div>
          {sizeIsDesktop ? (
            <div className="login-bgImage">
              <p className="login-signIn-copy">Meet better.</p>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
}
