import React from 'react';
import closeIcon from "../../assets/images/x-close-black.svg";

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  title: string;
}

export default function ReviewSelectionsModal({ setIsOpen, children, title }: Props) {
  return (
    <div className="modalNotification" style={{ zIndex: '101' }}>
    <div
      className="modalNotification-container overflowVisible"
      style={{ maxWidth: "640px" }}
    >
      <div className="modalNotification-header modalNotification-header-error">
        <div className="modalNotification-header-actions">
          <div className="modalNotification-header-info">
            <h3>{title}</h3>
          </div>
          <img
            src={closeIcon}
            alt="close icon"
            className="modalNotification-header-actions-close"
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
      </div>
      <div
        className="modalNotification-main overflowVisible"
        style={{ marginBottom: "0" }}
      >
        {children}
      </div>
    </div>
  </div>
  )
}
