import React, { useContext, useState } from "react";
import calendarIcon from "../../assets/images/calendar.svg";
import markerPinIcon from "../../assets/images/marker-pin-01-blue.svg";
import { Button } from "../Buttons/Button";
import { logoColor } from "../../types/logoColor";
import { logoImage } from "../../types/logoImage";
import { getEventLogo } from "../../utilities/getEventLogoImage";
import pencilIcon from "../../assets/images/pencil-white.svg";
import { RSVPEventValue } from "../../utilities/RSVPEventContext";

interface Props {
  name: string;
  date: string;
  description: string;
  startTime: string;
  endTime?: string;
  location: string;
  onClick: () => void;
  color: logoColor | "pastEvent" | null;
  pattern: logoImage | null;
  timeDifference: number;
  withoutButton?: boolean;
  iconIsEditable?: boolean;
  slug: string;
}

export default function EventReviewBlockV2({
  name,
  date,
  slug,
  description,
  startTime,
  endTime,
  location,
  onClick,
  color,
  pattern,
  timeDifference,
  withoutButton,
  iconIsEditable,
}: Props) {
  const [iconIsHovered, setIconIsHovered] = useState(false);
  const eventDurationHours = Math.floor(timeDifference / 60);
  const eventDurationMinutes = timeDifference - 60 * eventDurationHours;
  const { eventSlug } = useContext(RSVPEventValue);

  return (
    <div className="eventReviewBlockV2">
      <div className={`eventReviewBlockV2-icon`} onClick={onClick}>
        <div
          className={`
            ${
              color === "pastEvent"
                ? "reviewEventDetailsPage-header-mainImage-pastEvent"
                : ""
            }
              reviewEventDetailsPage-header-mainImage
              reviewEventDetailsPage-header-mainImage-md
            ${
              color === logoColor.YELLOW
                ? "reviewEventDetailsPage-header-mainImage-yellow"
                : ""
            }
            ${
              color === logoColor.BLUELIGHT
                ? "reviewEventDetailsPage-header-mainImage-blueLight"
                : ""
            }
            ${
              color === logoColor.RED
                ? "reviewEventDetailsPage-header-mainImage-error"
                : ""
            }
            ${
              color === logoColor.PURPLE
                ? "reviewEventDetailsPage-header-mainImage-purple"
                : ""
            }
            ${
              color === logoColor.GREEN
                ? "reviewEventDetailsPage-header-mainImage-success"
                : ""
            }
            ${
              color === logoColor.ORANGE
                ? "reviewEventDetailsPage-header-mainImage-warning"
                : ""
            }
            cursorPointer
          `}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
          onMouseMove={() => iconIsEditable && setIconIsHovered(true)}
        >
          <img src={getEventLogo(pattern)} alt="" />
        </div>
        {iconIsHovered ? (
          <div
            className="eventReviewBlockV2-icon-hover"
            onMouseLeave={() => setIconIsHovered(false)}
          >
            <img src={pencilIcon} alt="" />
          </div>
        ) : null}
      </div>
      <div className="eventReviewBlockV2-content">
        <div className="eventReviewBlockV2-content-header">
          <div className="flex items-center gap-12">
            <h3
              className="eventReviewBlockV2-content-header-title"
              onClick={onClick}
            >
              {name}
            </h3>
          </div>
          {/* <div className='eventReviewBlockV2-content-header-time'>
            <img src={calendarIcon} alt="" />
            <p className='eventReviewBlockV2-content-header-description'>{date}</p>
          </div> */}
          {endTime ? (
            <div className="eventReviewBlockV2-content-header-time">
              <p className="eventReviewBlockV2-content-header-description eventReviewBlockV2-content-header-description-blue">
                {timeDifference >= 60
                  ? `${
                      eventDurationHours > 0
                        ? `${eventDurationHours} hours`
                        : ""
                    } ${
                      eventDurationMinutes > 0
                        ? `${eventDurationMinutes} minutes`
                        : ""
                    } `
                  : `${timeDifference} minutes`}
              </p>
            </div>
          ) : null}
        </div>
        <div className="flex flex-column gap-6">
          <p className="text-sm font-regular">{description.split('\n').map(string => <>{string}<br/></>)}</p>
          <div className="eventReviewBlockV2-content-content-info-block">
            <img src={calendarIcon} alt="" />
            <div className="eventReviewBlockV2-content-content-info-block-info">
              <p className="eventReviewBlockV2-content-header-description">
                {date ? `${date} • ` : ""} {startTime}{" "}
                {endTime ? ` - ${endTime}` : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center spaceBetween fullWidth">
          <div className="eventReviewBlockV2-content-content-info-block">
            <img src={markerPinIcon} alt="" />
            <div className="eventReviewBlockV2-content-content-info-block-info">
              <p className="eventReviewBlockV2-content-header-description color-primary-600 font-semibold">
                {location.split(",")[0]} {location.split(",")[1]}
              </p>
            </div>
          </div>
          {withoutButton ? null : (
            <Button
              buttonType="regular"
              hierarchy="newDesign-primary"
              size="lg"
              contentCenter
              onClick={onClick}
              newDesignPaddingNone
            >
              <p className="text-xs font-bold">
                {eventSlug === slug ? "RSVP" : "Event details"}
              </p>
            </Button>
          )}
        </div>
        {/* <div className='eventReviewBlockV2-content-content'>
          <div className='eventReviewBlockV2-content-content-info'>
            <div>
              <p className='text-sm font-regular'>{description}</p>
              <div className='eventReviewBlockV2-content-content-info-block'>
                <img src={calendarIcon} alt="" />
                <div className='eventReviewBlockV2-content-content-info-block-info'>
                  <p className='eventReviewBlockV2-content-header-description'>
                    {date}
                  </p>
                  <p className='eventReviewBlockV2-content-header-description'>•</p>
                  <p className='eventReviewBlockV2-content-header-description'>{startTime}</p>
                  {
                    endTime
                      ? <>
                          <p className='eventReviewBlockV2-content-header-description'>-</p>
                          <p className='eventReviewBlockV2-content-header-description'>{endTime}</p>
                        </>
                      : null
                  }
                </div>
              </div>
            </div>
            <div className='flex items-center spaceBetween fullWidth'>
              <div className='eventReviewBlockV2-content-content-info-block'>
                <img src={markerPinIcon} alt="" />
                <div className='eventReviewBlockV2-content-content-info-block-info'>
                  <p className='eventReviewBlockV2-content-header-description'>{location.split(',')[0]}</p>
                  <p className='eventReviewBlockV2-content-header-description eventReviewBlockV2-content-header-description-lite'>{location.split(',')[1]}</p>
                </div>
              </div>
              <Button
                buttonType='regular'
                hierarchy='newDesign-primary'
                size='lg'
                contentCenter
                onClick={onClick}
                newDesignPaddingNone
              >
                Event details
              </Button>
            </div>
          </div>
          <Button
            buttonType='regular'
            hierarchy='newDesign-primary'
            size='lg'
            contentCenter
            onClick={onClick}
            newDesignPaddingNone
          >
            Event details
          </Button>
        </div> */}
      </div>
    </div>
  );
}
